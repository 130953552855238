import React, { createContext, useState, useContext, useEffect } from "react";
import { AuthContext } from "./AuthContext.js";
import { Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { getPageRoute } from "src/services/Config.js";
import { customEvent } from "src/utils/gtag.js";
import { isGoogleCalendarConnectionCandidate } from "./Utils.js";

const TopNavbarContext = createContext();

export const useTopNavbar = () => {
  return useContext(TopNavbarContext);
};

export const TopNavbarProvider = ({ children }) => {
  const [showTopNavbar, setShowTopNavbar] = useState(false);
  const [topNavbarContent, setTopNavbarContent] = useState(null);
  const { user } = useContext(AuthContext);

  useEffect(() => {
    // Determine the status based on user properties
    const getUserStatus = () => {
      if (user?.surpassedSubscriptionBenefits) {
        return "SURPASSED_SUBSCRIPTION_BENEFITS";
      }
      if (user?.needsConsumerCalendarUpgrade) {
        return "UPGRADE_REQUIRED";
      }
      if (user?.hasInvalidRefreshToken) {
        return "INVALID_REFRESH_TOKEN";
      }
      if (isGoogleCalendarConnectionCandidate(user)) {
        return "GOOGLE_CONNECT";
      }
      if ((user && !user?.hasValidSubscription)) {
        return "CONNECT";
      }
      return "NO_NAVBAR";
    };

    switch (getUserStatus()) {
      case "SURPASSED_SUBSCRIPTION_BENEFITS":
        setTopNavbarContent(
          <>
            {" "}
            ‼️ <b>Important:</b> &nbsp;
            Your trial or Stanza+ membership has expired. Please either upgrade to
            {" "}
            <Link
              component={RouterLink}
              to={getPageRoute("upgrade", "UPGRADE_PLUS")}
              color="inherit"
            >
              Stanza+
            </Link>
            {" "}
            or downgrade to a
            {" "}
            <Link
              component={RouterLink}
              to={getPageRoute("help", "CHOOSE_FREE_PLAN")}
              color="inherit"
            >
              free plan.
            </Link>
          </>
        );
        setShowTopNavbar(true);
        break;
      case "UPGRADE_REQUIRED":
        setTopNavbarContent(
          <>
            {" "}
            You've hit the limit of free calendar subscriptions. Upgrade to&nbsp;
            <Link
              component={RouterLink}
              to={getPageRoute("upgrade", "UPGRADE_PLUS")}
              color="inherit"
            >
              Stanza+
            </Link>
            &nbsp;for unlimited calendar subscriptions.
          </>
        );
        setShowTopNavbar(true);
        break;

      case "GOOGLE_CONNECT":
        setTopNavbarContent(
          <>
            {" "}
            🎉 <b>New:</b> &nbsp;
            <Link
              component={RouterLink}
              onClick={() => {
                customEvent({
                  name: "nav_google_calendar_steps_top_banner",
                  category: "GoogleCalendarExperience",
                  label: "Navigate to Google Calendar Steps from Top Banner Click",
                  data: {
                    user_id: user._id,
                    user_email: user.email,
                    user_handle: user.handle ?? "no_handle",
                  },
                });
              }}
              to={getPageRoute("help", "SUBSCRIBING_GOOGLE_CALENDAR_STEPS")}
              color="inherit"
            >
              Connect your Google Calendar
            </Link>
            &nbsp;for faster updates, across all devices. &nbsp;
            <Link
              component={RouterLink}
              onClick={() => window.open("https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/google-connect", "_blank")}
              color="inherit"
            >
              Watch a demo
            </Link>
            &nbsp;of how it works.
          </>
        );
        setShowTopNavbar(true);
        break;
  
      case "CONNECT":
        setTopNavbarContent(
          <>
            {" "}
            🎉 <b>New:</b> &nbsp;
            <Link
              component={RouterLink}
              onClick={() => {
                customEvent({
                  name: "nav_other_calendar_steps_top_banner",
                  category: "OtherCalendarExperience",
                  label: "Navigate to Other Calendar Steps from Top Banner Click",
                  data: {
                    user_id: user._id,
                    user_email: user.email,
                    user_handle: user.handle ?? "no_handle",
                  },
                });
              }}
              to={getPageRoute("help", "SUBSCRIBING_CALENDAR_STEPS")}
              color="inherit"
            >
              Transparent updates
            </Link>
            —know when your calendar app last refreshed. &nbsp;
            <Link
              component={RouterLink}
              onClick={() => window.open("https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/transparent-updates", "_blank")}
              color="inherit"
            >
              How it works.
            </Link>
          </>
        );
        setShowTopNavbar(true);
        break;
      
      case "INVALID_REFRESH_TOKEN":
      setTopNavbarContent(
        <>
          {" "}
          ‼️ <b>Important:</b> &nbsp;
          There is an issue with your Google Calendar connection.
          &nbsp;
          <Link
            component={RouterLink}
            to={getPageRoute("help", "CONNECT_GOOGLE_CALENDAR")}
            color="inherit"
          >
            Reconnect now.
          </Link>

        </>
      );
      setShowTopNavbar(true);
      break;

      case "NO_NAVBAR":
      default:
        setShowTopNavbar(false);
        break;
    }
  }, [user]);

  return (
    <TopNavbarContext.Provider
      value={{
        showTopNavbar,
        setShowTopNavbar,
        topNavbarContent,
        setTopNavbarContent,
      }}
    >
      {children}
    </TopNavbarContext.Provider>
  );
};
