import React from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import { Link, Paper, Typography } from "@mui/material";
import { getPageRoute } from "src/services/Config.js";

export default function LibraryMessage({ user, tab }) {
    const navigate = useNavigate();

    if (user.freePlanOptIn && user.addedCalendars?.length > 2 && tab === "calendar") {
        return (
          <Paper
            variant="outlined"
            sx={{ p: 2, mt: 2 }}
            data-testid={`library-canceled-${tab}-copy`}
          >
            <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
              ⚠️ You are on the free plan, which limits you to 2 calendars. Please delete the extra calendars, and then{" "}
              <Link
                component={RouterLink}
                style={{ textDecoration: "none" }}
                onClick={() =>
                  window.open(
                    "https://app.gitbook.com/o/VjHUdnaT065z9fbPcQPw/s/ztAxH3pF3mc05Z4yg6cb/for-subscribers/editor/events-are-not-appearing-in-calendar",
                    "_blank"
                  )
                }
              >
                <b>resubscribe</b>
              </Link>{" "}
              to the calendars you want to keep.{" "}
              <br /> <br />
              If you change your mind, you can{" "}
              <Link
                component={RouterLink}
                to={(getPageRoute("upgrade", "UPGRADE_PLUS"))}
                style={{ textDecoration: "none" }}
              >
                <b>upgrade</b>
              </Link>{" "}
              to Stanza+ to keep all your calendars and groups. After renewing, you may need to wait for your events to reappear in your calendar. Please be patient.
            </Typography>
          </Paper>
        );
    }

    if (user.freePlanOptIn && user.addedCalendars?.length <= 2 && tab === "calendar") {
        return (
          <Paper
            variant="outlined"
            sx={{ p: 2, mt: 2 }}
            data-testid={`library-canceled-${tab}-copy`}
          >
            <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
              ⚠️ You are on the free plan, which limits you to 2 calendars. If you are not seeing events in your calendar, please{" "}
              <Link
                component={RouterLink}
                style={{ textDecoration: "none" }}
                onClick={() =>
                  window.open(
                    "https://app.gitbook.com/o/VjHUdnaT065z9fbPcQPw/s/ztAxH3pF3mc05Z4yg6cb/for-subscribers/editor/events-are-not-appearing-in-calendar",
                    "_blank"
                  )
                }
              >
                <b>resubscribe</b>
              </Link>
              .{" "}
              <br /> <br />
              If you would like unlimited access to our library,{" "}
              <Link
                component={RouterLink}
                to={(getPageRoute("upgrade", "UPGRADE_PLUS"))}
                style={{ textDecoration: "none" }}
              >
                <b>upgrade</b>
              </Link>{" "}
              to Stanza+.
            </Typography>
          </Paper>
        );
    }

    if (user.freePlanOptIn && tab === "group" && user.createdGroups.length > 0) {
        return (
          <Paper
            variant="outlined"
            sx={{ p: 2, mt: 2 }}
            data-testid={`library-canceled-${tab}-copy`}
          >
            <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
              ⚠️ You are on the free plan, which doesn't allow you to create groups. Please delete any groups you've created.{" "}
              <br /> <br />
              If you change your mind, you can{" "}
              <Link
                component={RouterLink}
                onClick={() => navigate(getPageRoute("upgrade", "UPGRADE_PLUS"))}
                style={{ textDecoration: "none" }}
            >
                <b>upgrade</b>
              </Link>{" "}
              to Stanza+ to keep all your calendars and groups. After renewing, you may need to wait for your events to reappear in your calendar. Please be patient.
            </Typography>
          </Paper>
        );
    }

    if (user.surpassedSubscriptionBenefits && (tab === "calendar" || (tab === "group" && user.createdGroups.length > 0))) {
        return (
          <Paper
            variant="outlined"
            sx={{ p: 2, mt: 2 }}
            data-testid={`library-canceled-${tab}-copy`}
          >
            <Typography variant="subtitle2" sx={{ lineHeight: 1.5 }}>
                ‼️ <b>Important:</b> &nbsp;
                Your trial or Stanza+ membership has expired, and events will no longer appear in your calendar. Please either
                {" "}
                <Link
                    component={RouterLink}
                    to={getPageRoute("upgrade", "UPGRADE_PLUS")}
                    color="inherit"
                    >
                    <b>upgrade to Stanza+</b>
                </Link>
                {" "}
                or downgrade to a
                {" "}
                <Link
                    component={RouterLink}
                    to={getPageRoute("help", "CHOOSE_FREE_PLAN")}
                    color="inherit"
                    >
                    <b>free plan.</b>
                </Link>
            </Typography>
          </Paper>
        );
    }

    return null;

}