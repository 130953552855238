import { useCallback, useState, useContext, useEffect, useRef } from "react";
import { throttle } from "lodash";
import { Box, Divider, Typography } from "@mui/material";
import TagsList from "./TagsList.js";
import { ClearOutlined, PanToolAlt } from "@mui/icons-material";
import { useUpdateTags } from "src/services/mutations/useUpdateTags.js";
import { useRemoveTags, getPageRoute } from "src/services/index.js";
import { AuthContext } from "src/utils/AuthContext.js";
import {
  TAGS_SIMPLE_VERSION,
  SHOW_SELECT_TAG_GUIDE,
} from "src/utils/useTrendingContent.js";
import { customEvent } from "src/utils/gtag.js";
import { useNavigate } from "react-router-dom";
import { useDimensions } from "src/utils/useDimensions.js";

const Rectangle = ({ sx = {}, onClick }) => {
  return (
    <Box
      onClick={onClick}
      sx={{
        display: "block",
        width: "26px",
        height: "6px",
        backgroundColor: "#79747E",
        opacity: 0.2,
        borderRadius: "100px",
        margin: "auto",
        transform: { xs: "translateX(-30%)", md: "translateX(0%)" },
        ...sx,
      }}
    />
  );
};

const TagsCollection = ({
  trendingCalendarTags,
  setTagsFilter,
  tagsFilter,
  isStaffPicks,
  isLoadingCalendarsByTags,
  relatedTags,
}) => {
  const { mutateAsync: updateTags } = useUpdateTags();
  const { mutateAsync: deleteTags } = useRemoveTags();
  const navigate = useNavigate();
  const { user, refetchAuth } = useContext(AuthContext);
  const [isSelectingTags, setIsSelectingTags] = useState(
    !TAGS_SIMPLE_VERSION ? isStaffPicks : true
  );
  const stickyRef = useRef(null);
  const originalTop = useRef(null);
  const { isMediumScreen } = useDimensions();

  useEffect(() => {
    let lastScrollY = window.scrollY;
    if (stickyRef.current) {
      originalTop.current =
        stickyRef.current.getBoundingClientRect().top + window.scrollY;
    }

    const handleScroll = throttle(() => {
      if (!stickyRef.current) return;
      const scrollY = window.scrollY;
      const scrollDiff = scrollY - lastScrollY;

      const isOutOfViewport =
        window.scrollY - originalTop.current > originalTop.current;
      if (scrollDiff < -30) {
        stickyRef.current.style.opacity = "1";
        stickyRef.current.style.pointerEvents = "auto";
      } else if (scrollDiff > 30 && isOutOfViewport) {
        stickyRef.current.style.opacity = "0";
        stickyRef.current.style.pointerEvents = "none";
      }

      lastScrollY = scrollY;
    }, 100);

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isLoadingCalendarsByTags) {
      window.scrollTo({
        top: document.documentElement.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [isLoadingCalendarsByTags]);

  const handleDeleteTag = useCallback(
    async (tag) => {
      setTagsFilter((prevState) => {
        let newStateSet = new Set(prevState);
        if (!TAGS_SIMPLE_VERSION) {
          if (newStateSet.size === 1) {
            newStateSet = new Set(["Staff Picks"]);
          } else {
            newStateSet.delete(tag);
          }
        } else {
          if (newStateSet.size !== 1) newStateSet.delete(tag);
        }
        return Array.from(newStateSet);
      });
      customEvent({
        name: "delete_home_tag",
        category: "HomeCalendarTags",
        label: "User or guest delete home tag",
        data: {
          user_id: user?._id ?? "guest",
          user_email: user?.email ?? "-",
          user_handle: user?.handle ?? "no_handle",
          tag,
        },
      });
      if (user) {
        try {
          await deleteTags({ tags: [tag] });
        } catch (error) {
          // was not able to delete user tag
          refetchAuth();
        }
      }
    },
    [deleteTags, refetchAuth, setTagsFilter, user]
  );

  const handleTagClick = useCallback(
    async (tags, action, tag) => {
      if (action === "change") {
        navigate(getPageRoute("home", "TAG", { tag }));
      }
      customEvent({
        name: `${action}_home_tag`,
        category: "HomeCalendarTags",
        label: `User or guest ${action} home tag`,
        data: {
          user_id: user?._id ?? "guest",
          user_email: user?.email ?? "-",
          user_handle: user?.handle ?? "no_handle",
          tag,
        },
      });
      if (user) {
        try {
          await updateTags({ tags });
        } catch (error) {
          // was not able to add related tag
          refetchAuth();
        }
      }
    },
    [navigate, refetchAuth, updateTags, user]
  );

  const toggleIsSelectingTags = () => {
    if (isStaffPicks) {
      return setIsSelectingTags(true);
    }
    setIsSelectingTags(!isSelectingTags);
  };

  return (
    <>
      <Box
        ref={stickyRef}
        sx={{
          display: "flex",
          flexDirection: "column",
          position: "sticky",
          top: "53px",
          ...(!isMediumScreen
            ? {
                width: "100%",
                padding: "1rem 0 0 0",
                ml: "0rem",
              }
            : {
                width: "calc(100% + 1rem)",
                padding: `${isSelectingTags ? "1" : "0.3"}rem 0 0 1rem`,
                ml: "-1rem",
              }),
          zIndex: "10",
          transition: "all 0.3s ease-in-out",
          background: "#ffffff",
        }}
      >
        {/* provides background to the container in desktop */}
        {!isMediumScreen && (
          <Box
            sx={{
              position: "absolute",
              top: "0%",
              left: "-10%",
              width: "120%",
              height: "97%",
              background: "#ffffff",
            }}
          />
        )}
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "8px",
            mb: !TAGS_SIMPLE_VERSION ? 2 : 0,
          }}
        >
          <TagsList
            tags={trendingCalendarTags}
            tagsFilter={tagsFilter}
            setTagsFilter={setTagsFilter}
            isStaffPicks={isStaffPicks}
            isSelectingTags={isSelectingTags}
            isLoading={trendingCalendarTags.length === 0}
            multipleSelect={!TAGS_SIMPLE_VERSION}
            rows={3}
            slideArrows={true}
            onTagClick={handleTagClick}
            sx={{ "& .MuiChip-label": { fontSize: "13px" } }}
          />
          <Rectangle
            onClick={toggleIsSelectingTags}
            sx={{ cursor: "pointer" }}
          />
          <Divider
            sx={{
              ...(!isMediumScreen
                ? {
                    width: "100%",
                    ml: "0rem",
                  }
                : {
                    width: "calc(100% + 1rem)",
                    ml: "-1rem",
                  }),
              mt: 1,
              mb: !TAGS_SIMPLE_VERSION ? 1 : 0,
            }}
          />
        </Box>
        {SHOW_SELECT_TAG_GUIDE && isStaffPicks && (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "75%",
              margin: "auto",
              justifyItems: "center",
              alignItems: "center",
              background: "#000000",
              borderRadius: "24px",
              color: "#ffffff",
              padding: "1rem 0 1.5rem 0",
            }}
          >
            <PanToolAlt
              color="#ffffff"
              sx={{
                fontWeight: "300",
                fontSize: "3.5rem",
              }}
            />
            <Typography variant="h5" sx={{ mt: 2 }}>
              Select a tag
            </Typography>
            <Typography variant="subtitle2" sx={{ mt: 1 }}>
              Start discovering calendars
            </Typography>
            <Typography variant="subtitle1" sx={{ mt: 3 }}>
              PERSONALIZE THIS PAGE
            </Typography>
          </Box>
        )}
        {!isStaffPicks && !TAGS_SIMPLE_VERSION && (
          <>
            <TagsList
              tags={tagsFilter}
              tagsFilter={tagsFilter}
              setTagsFilter={setTagsFilter}
              multipleSelect={true}
              isSelectingTags={true}
              disableSelection={true}
              chipProps={{
                onClick: () => {},
                onDelete: (tag) => handleDeleteTag(tag),
                deleteIcon: (
                  <ClearOutlined sx={{ fontSize: "1.125rem !important" }} />
                ),
                color: "primary",
                variant: "default",
                sx: {
                  height: "36px",
                  fontSize: "14px",
                  borderRadius: "30px",
                  pl: "10px",
                  "& span": {
                    textAlign: "center",
                    minWidth: "95px",
                  },
                },
              }}
              rows={2}
              sx={{ mb: 0, "&>div": { gap: 1.5 } }}
            />

            {relatedTags?.length > 0 || isLoadingCalendarsByTags ? (
              <>
                <Typography variant="h2" sx={{ mt: 3, mb: 2 }}>
                  Related Tags
                </Typography>
                <TagsList
                  tags={relatedTags}
                  tagsFilter={tagsFilter}
                  setTagsFilter={setTagsFilter}
                  multipleSelect={true}
                  isLoading={isLoadingCalendarsByTags}
                  isSelectingTags={true}
                  rows={2}
                  onTagClick={handleTagClick}
                  sx={{ mb: 2 }}
                />
              </>
            ) : (
              <Box sx={{ mb: 3 }} />
            )}

            <Divider
              sx={{
                ...(!isMediumScreen
                  ? {
                      width: "100%",
                      ml: "0rem",
                    }
                  : {
                      width: "calc(100% + 1rem)",
                      ml: "-1rem",
                    }),
                mt: 1,
                mb: 0,
              }}
            />
          </>
        )}
      </Box>
    </>
  );
};

export default TagsCollection;
