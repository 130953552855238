import { useRef, useLayoutEffect } from "react";
import { Typography, Box, Button, Paper, Icon } from "@mui/material";

function ActionCard({
  title,
  primaryButtonCTA,
  primaryAction,
  description,
  testid,
  iconName,
  sx,
  iconSx,
  ...props
}) {
  const containerRef = useRef(null);
  const buttonRef = useRef(null);

  useLayoutEffect(() => {
    const parent = containerRef.current.parentNode;
    parent.style.position = "relative";
    return () => {
      parent.style.position = "";
    };
  }, [primaryAction]);

  useLayoutEffect(() => {
    const button = buttonRef.current;
    if (!button || typeof primaryAction !== "string") return;
    if (button) {
      const stopEvents = [
        "mouseover",
        "mouseenter",
        "mousemove",
        "mouseout",
        "mouseleave",
        "click",
      ];
      stopEvents.forEach((eventName) => {
        button.addEventListener(
          eventName,
          (event) => event.stopPropagation(),
          true
        );
      });
    }
  }, []);

  return (
    <Paper
      data-testid={testid}
      variant="outlined"
      ref={containerRef}
      {...props}
      sx={{
        position: "absolute",
        top: "25vh",
        left: "50%",
        transform: "translate(-50%, -50%)",
        margin: "auto",
        minWidth: 275,
        maxWidth: 350,
        minHeight: 150,
        p: 0,
        textAlign: "center",
        mt: 0,
        border: "none",
        gap: 0,
        ...sx,
      }}
    >
      <Icon
        baseClassName="material-symbols-outlined"
        style={{ fontSize: "3rem", fontWeight: 300, marginBottom: "8px" }}
        sx={{ ...iconSx }}
      >
        {iconName}
      </Icon>
      <Typography variant="h5" component="div" sx={{ mb: 0.5 }}>
        {title}
      </Typography>
      <Typography
        variant="subtitle2"
        component="div"
        color="text.secondary"
        sx={{ mb: 2, textWrap: "pretty" }}
      >
        {description}
      </Typography>
      {primaryAction &&
        (typeof primaryAction === "string" ? (
          <Button ref={buttonRef} variant="text" fullWidth component="div">
            <a
              href={primaryAction}
              target="_blank"
              rel="noreferrer"
              style={{
                textDecoration: "auto",
                WebkitTextDecorationLine: "none",
                color: "#000000",
                height: "100%",
                display: "block",
                minWidth: "calc(100% + 16px)",
                textAlign: "center",
              }}
            >
              {primaryButtonCTA}
            </a>
          </Button>
        ) : (
          <Button variant="text" onClick={primaryAction} fullWidth>
            {primaryButtonCTA}
          </Button>
        ))}
    </Paper>
  );
}

export default ActionCard;
