import { useEffect } from "react";
import { Box, Skeleton } from "@mui/material";
import { extractImageColors } from "src/utils/Utils.js";
import { useQuery } from "@tanstack/react-query";

const EventProfileBg = ({
  profileImage,
  eventSummary,
  loading,
  backgroundMidHeight,
  backgroundFullHeight,
  sx,
  handleImageError,
  ...props
}) => {
  const {
    data: bgGradient,
    refetch,
    isError,
  } = useQuery(
    [profileImage, "bgGradient"],
    async () => {
      const { dark, vibrant } = await extractImageColors(profileImage);
      return `linear-gradient(180deg, ${vibrant}, ${dark})`;
    },
    { enabled: !loading && !!profileImage }
  );

  const filterImg = "drop-shadow(2px 2px 7px rgba(0, 0, 0, 0.6)";
  const imgStyle = {
    ...{
      ...(backgroundFullHeight
        ? {
            height: "100%",
            width: "50%",
            objectFit: "cover",
            objectPosition: "top",
          }
        : backgroundMidHeight
        ? {
            width: "auto",
            height: "80%",
            minWidth: "50%",
            objectFit: "contain",
          }
        : {
            width: "auto",
            height: "70px",
            minWidth: "70px",
            objectFit: "contain",
          }),
      filter: filterImg,
    },
  };

  useEffect(() => {
    if (isError) {
      handleImageError();
    }
  }, [isError]);

  return (
    <>
      {loading || !bgGradient ? (
        <Skeleton
          id="img-loader"
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "4px", ...sx }}
          width={props.width}
          height={Number(props.height.split("px")[0])}
        />
      ) : (
        <></>
      )}
      <Box
        display={!loading && !!profileImage && bgGradient ? "flex" : "none"}
        justifyContent="center"
        alignItems="center"
        bgcolor={"#f0f0f0"} // Set the background color
        border={`1px solid #d0d0d0`} // Set the border color
        borderRadius="8px" // Rounded corners
        sx={{
          background: bgGradient,
          maxWidth: "-webkit-fill-available",
          position: "relative",
          overflow: "hidden",
        }}
        {...Object.fromEntries(
          Object.entries(props).filter(([key]) => key !== "calendarId")
        )}
      >
        <img
          src={profileImage}
          alt={eventSummary}
          onError={handleImageError}
          style={{ ...imgStyle }}
        />
      </Box>
    </>
  );
};

export default EventProfileBg;
