import { useQuery } from "@tanstack/react-query";
import { useState, useEffect, useContext, useMemo } from "react";
import { getUpcomingEvents, getEventsFromIcsFiles } from "src/utils/Utils.js";
import { useGetUserEvents, useCalendarsByTags } from "src/services";
import { AuthContext } from "src/utils/AuthContext.js";
import { useCalendarSections } from "src/services/queries/useGetCalendarSections.js";
import { useUpdateTags } from "src/services/mutations/useUpdateTags.js";
import { useParams, useSearchParams } from "react-router-dom";
import { useSessionStorage } from "./useSessionStorage.js";
import onboardingTags from "src/onboarding-tags.json";

export const TAGS_SIMPLE_VERSION = true;
export const SHOW_SELECT_TAG_GUIDE = false;
export const SAVE_USER_TAGS = true;

export const useTrendingContent = () => {
  const { user } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  const [initialLoad, setInitialLoad] = useState(false);
  const [addedCalendars, setAddedCalendars] = useState([]);
  const [isAddedLoading, setIsAddedLoading] = useState(true);
  const trendingTags = useMemo(() => Object.values(onboardingTags).flat(), []);
  const { tag } = useParams();
  const [tagsFilter, setTagsFilter] = useSessionStorage(
    "tagsFilter",
    !TAGS_SIMPLE_VERSION
      ? ["Staff Picks"]
      : tag && trendingTags.includes(tag)
      ? [tag]
      : [trendingTags[0]]
  );
  const isUser = Boolean(user);

  const { data: dataUserEvents, refetch: refetchUserEvents } = useGetUserEvents(
    {
      user,
    },
    {
      enabled: isUser,
    }
  );
  useEffect(() => {
    if (isUser) {
      refetchUserEvents();
    }
  }, [isUser]);

  const { data: upcomingAddedEvents, refetch: refetchUpcomingAddedEvents } =
    useQuery(
      [`upcoming-added-events`, dataUserEvents],
      async () => {
        setInitialLoad(false);
        setAddedCalendars(dataUserEvents);
        if (dataUserEvents.length === 0) {
          setIsAddedLoading(false);
          return [];
        }
        let addedEvents = [];
        const callback = (events) =>
          (addedEvents = [...addedEvents, ...events]);
        await getEventsFromIcsFiles(dataUserEvents, callback);
        setIsAddedLoading(false);
        return getUpcomingEvents(addedEvents, 100, true);
      },
      {
        initialData: [],
        enabled: !!dataUserEvents,
        refetchOnMount: true,
      }
    );

  useEffect(() => {
    if (dataUserEvents) {
      refetchUpcomingAddedEvents();
    }
  }, [dataUserEvents]);

  const { data: calendarSections, isLoading: isCalendarSectionsLoading } =
    useCalendarSections();

  let {
    data: searchByTagsData,
    isFetching: isLoadingCalendarsByTags,
    isFetchingNextPage: isFetchingNextPageCalendarsByTags,
    fetchNextPage: fetchSearchByTagsNextPage,
  } = useCalendarsByTags({ tags: tagsFilter, pageSize: 10 });
  if (isFetchingNextPageCalendarsByTags) {
    isLoadingCalendarsByTags = false;
  }
  const {
    subscriberAZCalendars = null,
    leaderboardCalendars = null,
    relatedTags = [],
    hasMoreSearchByTagsPages,
  } = searchByTagsData || {};
  const isStaffPicks = tagsFilter[0] === "Staff Picks";
  const { mutateAsync: updateTags } = useUpdateTags();
  useEffect(() => {
    const noUserTags = user && (!user?.tags || user?.tags?.length === 0);
    if (user?.tags?.length > 0) {
      // Load user tags after sign in
      setTagsFilter(user.tags);
    } else if (noUserTags && !isStaffPicks) {
      // Save edited tags after sign in from guest user
      updateTags({ tags: [...tagsFilter] });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (
      searchParams.tag === "discover" ||
      new URLSearchParams(window.location.search).get("tab") === "discover"
    ) {
      setInitialLoad(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (TAGS_SIMPLE_VERSION && tag && trendingTags.includes(tag)) {
      setTagsFilter([tag]);
    }
  }, [tag, trendingTags]);

  return {
    refetchUserEvents,
    addedCalendars,
    setAddedCalendars,
    isAddedLoading,
    upcomingAddedEvents,
    initialLoad,
    setInitialLoad,
    isTrendingLoading: isCalendarSectionsLoading,
    calendarSections,
    tagsFilter,
    setTagsFilter,
    isStaffPicks,
    subscriberAZCalendars,
    leaderboardCalendars,
    isLoadingCalendarsByTags,
    relatedTags,
    hasMoreSearchByTagsPages,
    fetchSearchByTagsNextPage,
  };
};
