import { memo, useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroller";
import { useSessionStorage } from "src/utils/useSessionStorage.js";
import { CircularProgress } from "@mui/material";

const InfiniteScrollList = ({
  children,
  element = "div",
  useWindow = true,
  loadMore,
  hasMore,
  initialLoad = true,
  getScrollParent,
  id,
  grid = false,
  ...props
}) => {
  const [limit, setLimit] = useSessionStorage(`${id}Limit`, 10);
  const childrens = children instanceof Array ? children : [children];

  const loadFunc = () => {
    setLimit(limit + 10);
  };

  return (
    <InfiniteScroll
      element={element}
      pageStart={1}
      loadMore={loadMore ?? loadFunc}
      hasMore={hasMore ?? childrens.length > limit}
      loader={
        <div
          key={0}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            width: grid ? "200%" : "100%",
            padding: "16px 0",
          }}
        >
          <CircularProgress thickness={2} size={30} />
        </div>
      }
      useWindow={useWindow}
      initialLoad={initialLoad}
      getScrollParent={getScrollParent}
      {...props}
    >
      {loadMore ? childrens : childrens.slice(0, limit)}
    </InfiniteScroll>
  );
};

export default memo(InfiniteScrollList);
