import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";
import { useMutationMock } from "./useMutationMock.js";
import { SAVE_USER_TAGS } from "src/utils/useTrendingContent.js";

export async function removeTags({ tags }) {
  if (!Array.isArray(tags) || tags.length === 0) {
    throw new Error("Tags must be a non-empty array");
  }

  const response = await axios.post(
    getApiRoute("user", "REMOVE_TAGS"),
    { tags },
    { withCredentials: true }
  );
  return response.data;
}

export const useRemoveTags = (options = {}) => {
  const mutation = useMutation(removeTags, options);
  const mutationMock = useMutationMock();
  return !SAVE_USER_TAGS ? mutationMock : mutation;
};
