import { useEffect, useContext } from "react";
import { useTheme } from "@emotion/react";
import { useNavigate } from "react-router-dom";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { getPageRoute } from "src/services";
import {
  Icon,
  Typography,
  Button,
  Link
} from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import InstructionsLayout from "../InstructionsLayout.js";
import FAQAccordion from "../FAQAccordian.js";
import { MoreVertOutlined } from "@mui/icons-material";

const CalendarConnected = () => {
  const navigate = useNavigate();
  const { user } = useContext(AuthContext);
  const theme = useTheme();

  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  useEffect(() => {
    if (!user) return

    if (!user?.hasValidSubscription) {
      navigate(getPageRoute("home", "HOME"));
      setTimeout(() => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "This feature is only for Stanza+ members.",
          "warning"
        );
      }, 1000);
    }
  }, [!!user]);

  const faqData = [
    {
      question: "How long will it take it to for calendars and groups to reflect timestamps?",
      answer:
        <>
        After you resubscribe to a calendar or group, timestamps will be immediately visible within calendar events in your calendar app. <br></br><br></br>
        Make sure to delete any previously added subscriptions as they will not show timestamps.
        </>
    },
    {
      question: "How can I learn more about this feature?",
      answer: (
        <>
        Check out this article on our {" "}
          <Link href="https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/transparent-updates" target="_blank" rel="noopener noreferrer">Help Center</Link>
        {" "} for more information on how transaprent updates work.
        </>
      ),
    },
    {
      question: "Some of my calendars don't show timestamps. How should I fix this?",
      answer: (
        <>
          Head to your Library and resubscribe to the calendar by clicking on the{" "}
          <Icon
            baseClassName="material-symbols-outlined"
            sx={{ verticalAlign: "middle", fontSize: "1rem", fontWeight: 600 }}
          >
            event_repeat
          </Icon>{" "}
          icon beside it. 
          <br></br><br></br>
          On mobile, you should be able to tap on the{" "}
          <MoreVertOutlined sx={{ verticalAlign: "middle", fontSize: "1.3rem" }} /> icon beside each calendar and select "Resubscribe".
          <br></br><br></br>
          You will be taken to your calendar app, and once you add the calendar again, you will see timestamps in the event description.
        </>
      ),
    }
  ];

  return (
    <>
      <InstructionsLayout>
        <Icon
          baseClassName="material-symbols-outlined"
          sx={{
            display: "block",
            margin: "0 auto",
            color: `${theme.palette.icon.primary}`,
            verticalAlign: "middle",
            fontSize: "3rem",
            mb: 1,
            fontWeight: 300,
          }}
        >
          check
        </Icon>
        <Typography variant={"h2"}>You're all set!</Typography>
        <Typography variant="h5" sx={{ mt: 4, width: "90%" }} color="text.primary">
          Head to your Library and resubscribe by clicking on the{" "}
          <Icon baseClassName="material-symbols-outlined" sx={{ verticalAlign: "middle", fontSize: "1.3rem", fontWeight: 600 }}>
            event_repeat
          </Icon>{" "}{" "}
          icon beside each calendar.
        </Typography>
        <Button
          variant="contained"
          size="grown"
          sx={{ mt: 2, width: "100%" }}
          onClick={() => navigate(getPageRoute("library", "LIBRARY"))}
        >
          GO TO LIBRARY
        </Button>
        <Typography variant={"h2"} sx={{ mt: 5, mb: 2 }}>
          FAQs
        </Typography>
        <FAQAccordion faqData={faqData} />
        <Button
          size="grown"
          variant="outlined"
          onClick={() => window.open("https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/transparent-updates")}
          sx={{ mt: 3, mb: 10, height: "auto", width: "100%" }}
        >
          See all FAQs
        </Button>
      </InstructionsLayout>
    </>
  );
};

export default CalendarConnected;
