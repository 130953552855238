import {
  useCallback,
  useMemo,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Box, Chip, Skeleton, useMediaQuery } from "@mui/material";
import { ChevronLeft, ChevronRight } from "@mui/icons-material";
import useEmblaCarousel from "embla-carousel-react";
import { WheelGesturesPlugin } from "embla-carousel-wheel-gestures";
import { TAGS_SIMPLE_VERSION } from "src/utils/useTrendingContent.js";

const ENABLE_SORTING_ON_TAG_CHANGE = false;

const TagsList = ({
  tags,
  setTagsFilter,
  tagsFilter,
  isStaffPicks = true,
  isSelectingTags,
  isLoading,
  multipleSelect = false,
  onTagClick = () => {},
  rows = 2,
  sx,
  chipProps = {},
  disableSelection = false,
  slideArrows = false,
}) => {
  const [emblaRef, emblaApi] = useEmblaCarousel(
    {
      align: "start",
      dragFree: true,
      containScroll: false,
      skipSnaps: false,
      ...(slideArrows
        ? {
            slidesToScroll: 3,
          }
        : {}),
    },
    [WheelGesturesPlugin({ forceWheelAxis: "x" })]
  );

  const [canScrollPrev, setCanScrollPrev] = useState(false);
  const [canScrollNext, setCanScrollNext] = useState(false);

  useEffect(() => {
    if (!emblaApi) return;

    const updateScrollState = () => {
      setCanScrollPrev(emblaApi.canScrollPrev());
      setCanScrollNext(emblaApi.canScrollNext());
    };

    emblaApi.on("select", updateScrollState);
    updateScrollState();

    return () => emblaApi.off("select", updateScrollState);
  }, [emblaApi]);

  const chipRefs = useRef([]);
  const [averageChipWidth, setAverageChipWidth] = useState(90);
  const [minWidthAdjustment, setMinWidthAdjustment] = useState(0);
  const chipHeightRef = useRef(0);

  useEffect(() => {
    if (chipRefs.current.length > 0) {
      const totalHeight = chipRefs.current.reduce(
        (maxHeight, chip) => Math.max(maxHeight, chip?.offsetHeight || 32),
        0
      );
      chipHeightRef.current = totalHeight;

      const totalWidth = chipRefs.current.reduce(
        (sum, chip) => sum + (chip?.offsetWidth || 90),
        0
      );
      setAverageChipWidth(totalWidth / chipRefs.current.length);
      chipRefs.current = [];
    }
  }, [tags, isLoading]);

  const selectedTags = useMemo(
    () => (disableSelection ? [] : tagsFilter),
    [disableSelection, tagsFilter]
  );

  const handleSelectTag = useCallback(
    (tag) => {
      let action = "";
      let newTags = [];
      if (multipleSelect) {
        setTagsFilter((prevFilter) => {
          if (prevFilter.includes(tag)) {
            newTags = prevFilter.filter((t) => t !== tag);
            action = "delete";
            if (newTags.length === 0) {
              if (!TAGS_SIMPLE_VERSION) {
                newTags = ["Staff Picks"];
              } else {
                newTags = prevFilter;
              }
            }
          } else {
            action = "add";
            newTags = [...prevFilter, tag].filter((t) => t !== "Staff Picks");
          }
          return newTags;
        });
      } else {
        newTags = [tag];
        action = "change";
        setTagsFilter(newTags);
      }

      onTagClick(newTags, action, tag);
    },
    [setTagsFilter, multipleSelect, onTagClick]
  );

  const getChipStyle = useCallback(
    (tag) => {
      return selectedTags.includes(tag)
        ? {
            color: "primary",
            variant: "default",
          }
        : {
            color: "default",
            variant: "outlined",
          };
    },
    [selectedTags]
  );

  const SkeletonTagContent = useMemo(
    () => [
      "stanza-plus",
      "nhl",
      "locations-toronto",
      "l.a.-sports",
      "bay-area-sports",
      "top-teams-on-stanza",
      "goat-sports",
      "basketball",
      "playoffs",
      "recs-fallback",
      "top-schedules-of-2021",
      "upcoming-in-april",
      "for-hawks-fans",
      "nba-playoffs-schedules",
      "sports-in-april",
      "sports-in-may",
      "only-on-stanza",
      "top-sports-2023",
      "nba-fans-love",
    ],
    []
  );

  const handledTags = useMemo(() => {
    if (isStaffPicks || TAGS_SIMPLE_VERSION) {
      return tags;
    } else {
      const newTags = tags.slice(1);
      if (ENABLE_SORTING_ON_TAG_CHANGE) {
        selectedTags.forEach((tag) => {
          const index = newTags.findIndex((e) => e === tag);
          if (index !== -1) {
            newTags.splice(index, 1);
            newTags.unshift(tag);
          }
        });
      }
      return newTags;
    }
  }, [isStaffPicks, selectedTags, tags]);

  const tagsLength = !isLoading ? tags.length : SkeletonTagContent.length;

  useEffect(() => {
    if (!emblaApi?.rootNode()) return;
    const adjustMinWidth = () => {
      const gap = sx?.["&>div"]?.gap === 1.5 ? 12 : 8;
      const chipHeight = chipHeightRef.current;
      if (chipHeight > 0) {
        const containerHeight = emblaApi.rootNode().offsetHeight;
        let renderedRows = Math.ceil(containerHeight / (chipHeight + gap));
        if (renderedRows > rows) {
          setMinWidthAdjustment((prev) => prev + 1);
          requestAnimationFrame(adjustMinWidth);
        }
      }
    };
    const observer = new ResizeObserver(adjustMinWidth);
    observer.observe(emblaApi.rootNode());
    return () => observer.disconnect();
  }, [emblaApi, isLoading]);

  const isMdUp = useMediaQuery((theme) => theme.breakpoints.up("md"));

  return (
    <Box sx={{ position: "relative" }}>
      <Box
        ref={emblaRef}
        sx={{
          overflow: "hidden",
          maxHeight: isSelectingTags ? "999px" : "0px",
          transition: "all ease 300ms",
          mb: isSelectingTags ? 1.5 : 0,
          ...sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            gap: "8px",
            minWidth: `calc(${Math.ceil(tagsLength / rows)} * ${
              averageChipWidth +
              (sx?.["&>div"]?.gap === 1.5 ? 16 : 10) +
              minWidthAdjustment
            }px)`,
            width: "100%",
          }}
        >
          {isLoading
            ? SkeletonTagContent.map((tag, index) => (
                <Skeleton
                  ref={(el) => (chipRefs.current[index] = el)}
                  key={tag}
                  animation="wave"
                  component="div"
                  variant="rounded"
                  sx={{ borderRadius: "19px" }}
                >
                  <Chip label={tag} />
                </Skeleton>
              ))
            : handledTags.map((tag, index) => (
                <Chip
                  ref={(el) => (chipRefs.current[index] = el)}
                  data-testid={`tag-${tag}`}
                  key={tag}
                  label={tag}
                  {...getChipStyle(tag)}
                  onClick={(e) => {
                    document.activeElement?.blur();
                    setTimeout(() => {
                      document.activeElement?.blur();
                    }, 100);
                    handleSelectTag(tag);
                  }}
                  {...(chipProps
                    ? {
                        ...chipProps,
                        onDelete: chipProps?.onDelete
                          ? () => chipProps?.onDelete?.(tag)
                          : undefined,
                      }
                    : {})}
                />
              ))}
        </Box>
      </Box>
      {slideArrows && isMdUp && emblaApi && (
        <>
          <Box
            onClick={() => canScrollPrev && emblaApi.scrollPrev()}
            sx={{
              position: "absolute",
              left: "-3rem",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
              cursor: "pointer",
              borderRadius: "50%",
              p: 0.5,
              opacity: !isSelectingTags ? 0 : canScrollPrev ? 1 : 0.1,
              transition: "opacity 0.2s ease",
              pointerEvents: canScrollPrev ? "auto" : "none",
            }}
          >
            <ChevronLeft fontSize="large" />
          </Box>
          <Box
            onClick={() => canScrollNext && emblaApi.scrollNext()}
            sx={{
              position: "absolute",
              right: "-3rem",
              top: "50%",
              transform: "translateY(-50%)",
              zIndex: 1,
              cursor: "pointer",
              borderRadius: "50%",
              p: 0.5,
              opacity: !isSelectingTags ? 0 : canScrollNext ? 1 : 0.1,
              transition: "opacity 0.2s ease",
              pointerEvents: canScrollNext ? "auto" : "none",
            }}
          >
            <ChevronRight fontSize="large" />
          </Box>
        </>
      )}
    </Box>
  );
};

export default TagsList;
