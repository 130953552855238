import ActionCard from "src/components/blocks/ActionCard.js";
import { Box } from "@mui/material";

export const ErrorComponent = () => {
  return (
    <div
      data-testid="error-component"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        minHeight: "100vh",
      }}
    >
      <div>
        <Box
          sx={{
            margin: "auto",
            marginTop: "0",
            maxWidth: "600px",
            p: 2,
            mb: 10,
          }}
        >
          <ActionCard
            data-testid="error-card"
            title="Unexpected error"
            description="We've been notified of the error."
            primaryButtonCTA="Refresh page"
            primaryAction={() => window.location.reload()}
            iconName="error"
            iconSx={{ color: "error.main" }}
          />
        </Box>
      </div>
    </div>
  );
};
