import { useContext, useEffect } from "react";
import StepsScreen from "../StepsScreen.js";
import { Box, Typography, Divider } from "@mui/material";
import { UpgradeConsumerButton } from "../../upgrades/UpgradeConsumerButton.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { useNavigate } from "react-router-dom";
import { getPageRoute, getApiRoute } from "src/services/Config.js";
import { useMutation } from "@tanstack/react-query";
import { LoadingButton } from "src/components/blocks/LoadingButton.js";
import axios from "axios";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";

const ChooseFreePlan = () => {
  const { user, refetchAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  useEffect(() => {
    if (user?.hasValidSubscription) {
      navigate(getPageRoute("home", "HOME"));
    }

    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  let connectTitle, connectMessage;
  if (user.provider === "google") {
    connectTitle = "Google Connect will be disconnected";
    connectMessage = "You won't be able to subscribe to new calendars on mobile. Instead, you will need to add on desktop, and then manually sync with mobile.";
  } else {
    connectTitle = "Connect will be turned off";
    connectMessage = "You will no longer see when your calendar last updated in your calendar app. Your events may be out of date."
  }

  const steps = [
    {
      title: connectTitle,
      description: connectMessage,
      icon: "link_off",
    },
    {
      title: "Transparent updates will be turned off",
      description: "You will no longer know when your calendar last updated. Your events may be out of date.",
      icon: "visibility_off",
    },
    {
      title: "Go down to 2 calendars and resubscribe",
      description: "Once you're below the limit, you'll need to resubscribe to the calendars you want to keep.",
      icon: "delete",
    },
  ];

  const {
    mutateAsync: handleChooseFreePlan,
    isLoading: isLoadingChooseFreePlan,
  } = useMutation(async () => {
    try {
      await axios.post(
        getApiRoute("user", "CHOOSE_FREE_PLAN"),
        {}, // no body needed
        {
          withCredentials: true,
        }
      );
      setTimeout(async () => {
        await refetchAuth();
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          "You've successfully switched to the free plan.",
          "success"
        )
        navigate(getPageRoute("library", "LIBRARY"));
      }, 2000);
    } catch (error) {
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "⚠️ There was an error switching you to the free plan. Please try again.",
        "error"
      );
      console.error(error);
    }
  });

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Box
        id="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "5% auto 0 auto",
          maxWidth: "400px",
          position: "relative",
          p: 2,
          px: 3.5,
          alignItems: "center",
          textAlign: "center",
        }}
      >
        <StepsScreen
          title="Switch to the free plan"
          steps={steps}
          nav="back"
          sx={{ maxWidth: "400px" }}
        > 
          <LoadingButton
            size="grown"
            variant="outlined"
            onClick={handleChooseFreePlan}
            loading={isLoadingChooseFreePlan}
            sx={{ height: "auto", width: "100%" }}
          >
            Continue with free plan
          </LoadingButton>
          <Divider sx={{ my: 2 }}>
            <Typography variant="subtitle2">or</Typography>
          </Divider>
          <UpgradeConsumerButton >
            UPGRADE TO STANZA+
          </UpgradeConsumerButton>
          <Typography
            variant="subtitle2"
            sx={{
              pt: 1.5,
              margin: "auto",
              color: "text.secondary",
              mb: 10,
              width: "90%",
            }}
          >
            Keep all your current calendars and benefits.
          </Typography>
        </StepsScreen>
      </Box>
    </div>
  );
};

export default ChooseFreePlan;
