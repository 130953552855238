import axios from "axios";
import { getApiRoute } from "src/services";
import { getEventsFromRecentEvents } from "src/utils/Utils";
import { useInfiniteQuery } from "@tanstack/react-query";
import { useMemo } from "react";

async function resolveCalendarsWithRecentEvents(calendars) {
  const calendarsResolved = [];
  const setFn = (subcomponents) => {
    const calendarsIdResolved = new Set(
      calendarsResolved.map((calendar) => calendar.calendarId)
    );
    for (let i = 0; i < subcomponents.length; i++) {
      if (!calendarsIdResolved.has(subcomponents[i].calendarId)) {
        calendarsResolved.push(subcomponents[i]);
        calendarsIdResolved.add(subcomponents[i].calendarId);
      }
    }
  };
  await getEventsFromRecentEvents(calendars, setFn);
  return calendarsResolved;
}

function resolveRelatedTags(uniqueTags, searchTagsSet) {
  const relatedTags = [];
  const addedTags = new Set();
  for (const tag of uniqueTags) {
    const lowerTag = tag.toLowerCase();
    if (
      !searchTagsSet.has(lowerTag) &&
      !addedTags.has(lowerTag) &&
      tag.trim()
    ) {
      relatedTags.push(tag);
      addedTags.add(lowerTag);
    }
  }
  return relatedTags;
}

async function leaderboardByTags(tags) {
  const response = await axios.post(
    getApiRoute("home", "GET_LEADERBOARD_BY_TAGS"),
    { tags },
    { withCredentials: true }
  );
  let leaderboardCalendars = [];
  if (response?.data?.leaderboard && Array.isArray(response.data.leaderboard)) {
    leaderboardCalendars = await resolveCalendarsWithRecentEvents(
      response.data.leaderboard
    );
  }
  return leaderboardCalendars;
}

export async function calendarsByTags(params) {
  const response = await axios.post(
    getApiRoute("home", "GET_CALENDARS_BY_TAGS"),
    params,
    { withCredentials: true }
  );
  const subscriberAZCalendars = await resolveCalendarsWithRecentEvents(
    response.data.calendarsWithSubscribers
  );
  return { ...response.data, subscriberAZCalendars };
}

export const useCalendarsByTags = (
  params = { tags: [], pageSize: 50 },
  options = {}
) => {
  const query = useInfiniteQuery(
    ["calendars-by-tags", params.tags, params.pageSize],
    async ({ pageParam = 1 }) => {
      const data = await calendarsByTags({ ...params, page: pageParam });
      const leaderboardCalendars =
        pageParam === 1 ? await leaderboardByTags(params.tags) : [];
      return { ...data, leaderboardCalendars: [...leaderboardCalendars] };
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        //Iif the last page doesn't has pageSize length, there are no more pages
        if (lastPage.calendarsWithSubscribers.length !== params.pageSize) {
          return undefined;
        }
        // Return the next page number
        return allPages.length + 1;
      },
      enabled: !!params.tags && params.tags.length > 0, // Only execute if there are tags
      ...options,
    }
  );

  const subscriberAZCalendars = useMemo(() => {
    if (!query.data) return [];
    return query.data.pages.flatMap((page) => page.subscriberAZCalendars);
  }, [query.data]);

  // Accumulate all unique tags from pages, removing case-insensitive duplicates
  const allUniqueTags = useMemo(() => {
    if (!query.data) return [];
    const allTags = query.data.pages.flatMap((page) => page.uniqueTags);
    const seen = new Set();
    const result = [];
    allTags.forEach((tag) => {
      const lower = tag.toLowerCase();
      if (!seen.has(lower) && tag.trim()) {
        seen.add(lower);
        result.push(tag);
      }
    });
    return result;
  }, [query.data]);

  // Calculate relatedTags when allUniqueTags or search tags change
  const relatedTags = useMemo(() => {
    const searchTagsSet = new Set(params.tags.map((t) => t.toLowerCase()));
    return resolveRelatedTags(allUniqueTags, searchTagsSet);
  }, [allUniqueTags, params.tags]);

  return {
    ...query,
    data: {
      subscriberAZCalendars,
      totalCalendars:
        query.data?.pages[query.data.pages.length - 1].totalCalendars,
      leaderboardCalendars: query.data?.pages?.[0]?.leaderboardCalendars,
      relatedTags,
      hasMoreSearchByTagsPages: query.hasNextPage,
    },
  };
};
