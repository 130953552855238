import { createTheme, Icon } from "@mui/material";
import {
  CheckOutlined,
  PriorityHighRounded,
  CancelOutlined,
} from "@mui/icons-material";
import { min } from "lodash";

const palette = {
  primary: {
    main: "#000000",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#ffffff",
    contrastText: "#000000",
  },
  success: {
    main: "#209C33",
  },
  info: {
    main: "#000000",
  },
  warning: {
    main: "#FF7F07",
  },
  error: {
    main: "#FF4C4C",
  },
  delete: {
    main: "#EB5757",
  },
  text: {
    primary: "#000000",
    secondary: "#00000085",
    tertiary: "#D9D9D9",
    quaternary: "#909090",
  },
  paper: {
    primary: "#ffffff",
    secondary: "#f5f5f5",
  },
  icon: {
    primary: "#1C1B1F",
  },
};

const MuiTabs = {
  styleOverrides: {
    root: {
      marginBottom: 3,
      position: "sticky",
      zIndex: 3,
      backgroundColor: palette.secondary.main,
      transition: "inset 0.1s linear",
      "& .MuiTabs-indicator": {
        height: "1px !important",
      },
      top: "0px",
      "@media (max-width:960px)": {
        marginLeft: "-1rem",
        marginRight: "-1rem",
      },
      "@media (min-width:960px)": {
        marginLeft: "-2rem",
        marginRight: "-2rem",
      },
    },
  },
};

const MuiTab = {
  styleOverrides: {
    root: {
      color: palette.text.secondary,
      textTransform: "uppercase",
      fontSize: "12px !important",
      lineHeight: "31.92px !important",
      fontWeight: "700 !important",
      letterSpacing: "1px !important",
      paddingBottom: "8px !important",
      "&.Mui-selected": {
        color: palette.text.primary,
      },
      borderBottom: `1px solid ${palette.text.tertiary}`,
    },
  },
};

const MuiButton = {
  styleOverrides: {
    root: {
      "&.Mui-disabled": {
        color: `${palette.text.disabled} !important`,
      },
      minWidth: "auto",
      textWrap: "nowrap",
    },
  },
  variants: [
    {
      props: { variant: "contained" },
      style: {
        borderRadius: "30px",
        padding: "10px 16px",
      },
    },
    {
      props: { variant: "outlined" },
      style: {
        borderRadius: "30px",
        padding: "10px 16px",
        border: `1px solid ${palette.text.tertiary}`,
      },
    },
    {
      props: { variant: "outlined", size: "grown" },
      style: {
        borderRadius: "30px",
        padding: "10px 24px !important",
        border: `1px solid ${palette.text.tertiary}`,
      },
    },
    {
      props: { variant: "contained", size: "shrinked" },
      style: {
        borderRadius: "30px",
        padding: "6px 16px !important",
      },
    },
    {
      props: { variant: "outlined", size: "shrinked" },
      style: {
        borderRadius: "30px",
        padding: "6px 16px !important",
        border: `1px solid ${palette.text.tertiary}`,
      },
    },
    {
      props: { variant: "contained", size: "small" },
      style: {
        borderRadius: "15px",
        padding: "8px 10px !important",
        fontSize: "10px !important",
        lineHeight: "1 !important",
        "@media (max-width:960px)": {
          maxHeight: "23px",
        },
      },
    },
    {
      props: { variant: "outlined", size: "small" },
      style: {
        borderRadius: "15px",
        padding: "8px 10px !important",
        border: `1px solid ${palette.text.tertiary}`,
        fontSize: "10px !important",
        lineHeight: "1 !important",
      },
    },
    {
      props: { variant: "outlined", size: "micro" },
      style: {
        padding: "4px 6px !important",
        fontSize: "10px !important",
        lineHeight: "1 !important",
      },
    },
    {
      props: { variant: "outlined-squared" },
      style: {
        borderRadius: "4px",
        padding: "8px 24px !important",
        border: `1px solid ${palette.text.tertiary}`,
      },
    },
  ],
};

const MuiIconButton = {
  styleOverrides: {
    root: {
      color: `${palette.icon.primary}`,
      "& span": {
        verticalAlign: "middle",
      },
    },
  },
  variants: [
    {
      props: { variant: "contained" },
      style: {
        padding: "8px",
        backgroundColor: palette.primary.main,
        "&:hover": {
          backgroundColor: palette.primary.main,
        },
      },
    },
    {
      props: { variant: "outlined" },
      style: {
        padding: "8px",
        backgroundColor: palette.secondary.main,
        border: `1px solid ${palette.text.tertiary}`,
      },
    },
  ],
};

const MuiChip = {
  styleOverrides: {
    root: {
      fontSize: "12px",
      lineHeight: "16.2px",
      fontWeight: 400,
    },
  },
  variants: [
    {
      props: { variant: "default" },
      style: {
        height: "auto",
        borderRadius: "19px",
        padding: "3.8px",
        border: `1px solid transparent`,
      },
    },
    {
      props: { variant: "filled" },
      style: {
        height: "auto",
        borderRadius: "19px",
        padding: "3.8px",
      },
    },
    {
      props: { variant: "outlined" },
      style: {
        height: "auto",
        borderRadius: "19px",
        padding: "3.8px",
        borderColor: palette.text.tertiary,
      },
    },
  ],
};

const MuiAlert = {
  styleOverrides: {
    root: {
      fontSize: "14px",
      lineHeight: "18.9px",
      fontWeight: 400,
      borderRadius: "34px",
    },
  },
  defaultProps: {
    iconMapping: {
      success: <CheckOutlined fontSize="inherit" />,
      info: (
        <Icon baseClassName="material-symbols-outlined">import_contacts</Icon>
      ),
      warning: <PriorityHighRounded fontSize="inherit" />,
      error: <CancelOutlined fontSize="inherit" />,
    },
  },
};

const MuiAvatar = {
  variants: [
    {
      props: { size: "small" },
      style: {
        width: "20px",
        height: "20px",
        fontSize: "16px",
      },
    },
  ],
};

const MuiTextField = {
  variants: [
    {
      props: { type: "search" },
      style: {
        borderRadius: "29px",
        border: `1px solid ${palette.text.tertiary}`,
        "& .MuiInputBase-input": {
          padding: "10px",
          fontSize: "14px",
        },
        "& fieldset": {
          display: "none",
        },
      },
    },
    {
      props: { variant: "standard" },
      style: {
        "& .MuiInputBase-root:before": {
          borderBottom: "1px solid #D9D9D9",
        },
      },
    },
  ],
};

export const Theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600, // mobile
      md: 960, // tablet
      lg: 1280, // desktop
      xl: 1920, // large desktop
    },
  },
  palette,
  components: {
    MuiTabs,
    MuiTab,
    MuiButton,
    MuiIconButton,
    MuiChip,
    MuiAlert,
    MuiAvatar,
    MuiTextField,
  },
  typography: {
    fontFamily: ["Satoshi", "Nunito", "Open Sans", "Arial"].join(","),
    h1: {
      "@media (max-width:960px)": {
        fontSize: "54px",
        lineHeight: "63px",
        fontWeight: 700,
        letterSpacing: "-0.4px",
      },
      "@media (min-width:960px)": {
        fontSize: "4rem",
        lineHeight: "1",
        fontWeight: 600,
      },
    },
    h2: {
      // Heading
      fontSize: "20px",
      lineHeight: "27px",
      fontWeight: 700,
      letterSpacing: "-0.4px",
    },
    title: {
      fontSize: "24px",
      lineHeight: "32.4px",
      fontWeight: 600,
      letterSpacing: "-0.4px",
      fontFamily: "Satoshi",
    },
    h5: {
      // Body Medium
      fontSize: "16px",
      lineHeight: "21.6px",
      fontWeight: 500,
    },
    body1: {
      // Body Regular
      fontSize: "16px",
      lineHeight: "21.6px",
      fontWeight: 400,
      // '@media (min-width:960px)': {
      //   fontSize: '1rem',
      //   lineHeight: '1.5',
      //   fontWeight: 400,
      // },
    },
    subtitle1: {
      // Subtitle Medium
      fontSize: "14px",
      lineHeight: "18.9px",
      fontWeight: 500,
      // '@media (min-width:960px)': {
      //   fontSize: '1rem',
      //   lineHeight: '1.5',
      //   fontWeight: 500,
      // },
    },
    subtitle2: {
      // Subtitle Regular
      fontSize: "14px",
      lineHeight: "18.9px",
      fontWeight: 400,
      // '@media (min-width:960px)': {
      //   fontSize: '1rem',
      //   lineHeight: '1.5',
      //   fontWeight: 400,
      // },
    },
    button: {
      textTransform: "uppercase",
      fontSize: "14px",
      lineHeight: "24px",
      fontWeight: 500,
      letterSpacing: "0.4px",
      // '@media (min-width:960px)': {
      //   fontSize: '0.875rem',
      //   lineHeight: '1.75',
      //   fontWeight: 500,
      // },
    },
    caption: {
      fontSize: "12px",
      lineHeight: "19.92px",
      fontWeight: 400,
      letterSpacing: "0.4px",
      // '@media (min-width:960px)': {
      //   fontSize: '0.8125rem',
      //   lineHeight: '1.66',
      //   fontWeight: 400,
      // },
      "&.MuiFormHelperText-root": {
        color: `${palette.text.secondary} !important`,
      },
    },
    footer: {
      color: "#979898 !important",
      fontSize: "12px",
      lineHeight: "1.5",
      fontWeight: 400,
      letterSpacing: "-0.4px",
      // '@media (min-width:960px)': {
      //   fontSize: '0.875rem',
      //   lineHeight: '1.43',
      //   fontWeight: 400,
      // },
    },
  },
  clampLines: (lines) => ({
    WebkitBoxOrient: "vertical",
    display: "-webkit-box",
    WebkitLineClamp: lines,
    overflow: "hidden",
  }),
  ellipsis: () => ({
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  }),
});
