import { useContext, useEffect } from "react";
import { Box, Typography, Icon, Button, Link } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useTheme } from "@emotion/react";
import { AuthContext } from "src/utils/AuthContext.js";
import { UpgradeConsumerButton } from "../../upgrades/UpgradeConsumerButton.js";
import { getPageRoute, getApiRoute } from "src/services/Config.js";
import Footer from "src/components/blocks/Footer.js";
import FAQAccordion from "../FAQAccordian.js";
import axios from "axios";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { useMutation } from "@tanstack/react-query";
import { LoadingButton } from "src/components/blocks/LoadingButton.js";
import { isGoogleCalendarConnectionCandidate } from "src/utils/Utils.js";

const FreeTrialCancel = () => {
  const { user, refetchAuth } = useContext(AuthContext);
  const navigate = useNavigate();
  const theme = useTheme();
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  useEffect(() => {
    if (user?.hasValidSubscription) {
      navigate(getPageRoute("home", "HOME"));
    }

    window.scrollTo({
      top: 0,
      behavior: "auto",
    });
  }, []);

  const faqData = [
    {
      question: "How do I cancel my free trial?",
      answer:
        "After you sign up for the free trial, you'll see 'Your Plan' in the drop down menu in the top right corner. Click on 'Your Plan' and then 'Cancel Subscription' on the next screen. It's as easy as as two clicks.",
    },
    {
      question: "What happens if I forget to cancel my free trial?",
      answer:
        "We get it – life happens. Submit a request via our support form by clicking on 'Report' in the top right menu. We'll refund your subscription within 30 days of the free trial ending.",
    },
    {
      question: "My payment information isn't being accepted.",
      answer: (
        <>
          If you're experiencing issues with a valid card not being accepted,
          please send us an email at{" "}
          <Link href="mailto:founders@stanza.co">founders@stanza.co</Link> with
          more details on the error. We'll look into it asap.
        </>
      ),
    },
  ];

  const {
    mutateAsync: handleStartFreeTrial,
    isLoading: isLoadingStartFreeTrial,
  } = useMutation(async () => {
    try {
      await axios.post(
        getApiRoute("user", "START_FREE_TRIAL"),
        {}, // no body needed
        {
          withCredentials: true,
        }
      );
      setTimeout(async () => {
        await refetchAuth();
        if (isGoogleCalendarConnectionCandidate(user)) {
          navigate(getPageRoute("help", "CONNECT_GOOGLE_CALENDAR"));
        } else {
          navigate(getPageRoute("upgrade", "PAYMENT_STANZA_PLUS_CONFIRMATION"));
        }
      }, 2000);
    } catch (error) {
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "⚠️ There was an error starting your free trial. Please try again.",
        "error"
      );
      console.error(error);
    }
  });

  return (
    <div
      style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}
    >
      <Box
        id="container"
        sx={{
          display: "flex",
          flexDirection: "column",
          margin: "5% auto 0 auto",
          maxWidth: "400px",
          position: "relative",
          p: 2,
          px: 3.5,
          alignItems: "center",
          textAlign: "center",
          flex: 1,
        }}
      >
        <Button
          variant="text"
          onClick={() => navigate(getPageRoute("home", "HOME"))}
          sx={{ color: "text.secondary", mr: "auto", px: 0 }}
        >
          GO HOME
        </Button>
        {!user.freeTrialStartDate ? (
          <>
            <Icon
              baseClassName="material-symbols-outlined"
              sx={{
                display: "block",
                margin: "0 auto",
                color: `${theme.palette.icon.primary}`,
                verticalAlign: "middle",
                fontSize: "4rem",
                mt: 3,
                mb: 1,
                fontWeight: 300,
              }}
            >
              rocket_launch
            </Icon>
            <Typography
              variant={"h2"}
              sx={{ mr: "auto", textAlign: "left", mt: 3 }}
            >
              Try Stanza+ for free for 3 days without a credit card.
            </Typography>
            <Typography
              variant={"subtitle2"}
              sx={{ mr: "auto", textAlign: "left", mt: 2.5, fontSize: "1rem" }}
              color="text.secondary"
            >
              If you love it, you'll need to enter your payment info before day
              3 to keep your membership active.
            </Typography>
            <LoadingButton
              size="grown"
              variant="contained"
              onClick={handleStartFreeTrial}
              loading={isLoadingStartFreeTrial}
              sx={{ mt: 3.5, mb: 10, height: "auto", width: "100%" }}
            >
              LET'S GO!
            </LoadingButton>
          </>
        ) : (
          <>
            <Icon
              baseClassName="material-symbols-outlined"
              sx={{
                display: "block",
                margin: "0 auto",
                color: `${theme.palette.icon.primary}`,
                verticalAlign: "middle",
                fontSize: "4rem",
                mt: 3,
                mb: 1,
                fontWeight: 300,
              }}
            >
              lightbulb_outline
            </Icon>
            <Typography
              variant={"h2"}
              sx={{ mr: "auto", textAlign: "left", mt: 3 }}
            >
              Looks like you've already tried a free trial without a credit
              card.
            </Typography>
            <Typography
              variant={"subtitle2"}
              sx={{ mr: "auto", textAlign: "left", mt: 2.5, fontSize: "1rem" }}
            >
              Add your payment details to get an extra 7 days of Stanza+. We'll
              notify you before any charges occur, giving you complete control
              over your subscription.
            </Typography>
            <Typography
              variant={"subtitle2"}
              sx={{ mr: "auto", textAlign: "left", mt: 2.5, fontSize: "1rem" }}
              color="text.primary"
            >
              Whether you choose to join us as a Stanza+ member or continue with
              our free plan, we're here to support your calendar needs. After
              all, that's why we offer flexible options—so you can choose what
              works best for you.
            </Typography>
            <UpgradeConsumerButton sx={{ mt: 3.5 }}>
              FINISH SIGNING UP
            </UpgradeConsumerButton>
            <Typography variant={"h2"} sx={{ mt: 5, mb: 2 }}>
              FAQs
            </Typography>
            <FAQAccordion faqData={faqData} />
            <Button
              size="grown"
              variant="outlined"
              onClick={() => navigate(getPageRoute("help", "FREE_TRIAL_STEPS"))}
              sx={{ mt: 3, mb: 10, height: "auto", width: "100%" }}
            >
              How your Free Trial Works
            </Button>
          </>
        )}
      </Box>
      <Footer />
    </div>
  );
};

export default FreeTrialCancel;
