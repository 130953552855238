import React, { useCallback, useMemo } from "react";
import useClamp from "src/utils/useClamp.js";

// Format description to replace any URLs with the 🔗 emoji to make it easier on the eye
export const useFormatDescription = ({ description, isEmbedded, setTicketUrl, setShopUrl, setWatchUrl }) => {

    // URL extraction for shop and watch links
    const extractLinks = useCallback((description) => {
        // Define regex for tickets, shop, and watch URLs
        const ticketUrlRegex = /(?:buy\s+)?tickets?:\s+(https?:\/\/\S+)/gi;
        const shopUrlRegex = /shop:\s+(https?:\/\/\S+)/gi;
        const watchUrlRegex = /watch(?:\s+on)?:\s+(https?:\/\/\S+)/gi;

        // Extract and set URLs
        const extractAndSetUrl = (regex, setter) => {
            const match = regex.exec(description);
            if (match) {
                setter(match[1].startsWith('www') ? `https://${match[1]}` : match[1]);
            }
            regex.lastIndex = 0; // Reset regex index for subsequent searches
        };

        extractAndSetUrl(ticketUrlRegex, setTicketUrl);
        extractAndSetUrl(shopUrlRegex, setShopUrl);
        extractAndSetUrl(watchUrlRegex, setWatchUrl);

        // Clean up the description
        const cleanDescription = description
            .replace("---", "")
            .replace(ticketUrlRegex, "")
            .replace(shopUrlRegex, "")
            .replace(watchUrlRegex, "")
            .replace(/Check out the full calendar here: https?:\/\/\S+/gi, "")
            .trim(); // Trim the result to remove any leading/trailing whitespaces

        return cleanDescription;
    }, [setShopUrl, setTicketUrl, setWatchUrl]);

    const cleanedDescription = useMemo(() => {
        if (!description) {
            return "";
        }
        // Remove tags from the description except <br> tags (line breaks)
        let cleanedDescription = description.replace(/<(?!br\s*\/?)[^>]+>/gi, " ");
        return extractLinks(cleanedDescription);
    }, [description, extractLinks]);

    const { truncatedText, readMore, setReadMore, needsReadMore } = useClamp({
        maxLength: isEmbedded ? 100 : 265,
        text: cleanedDescription,
    });

    const fn = useCallback((description) => {
        if (!description || !truncatedText || !cleanedDescription) {
            return "";
        }

        // Extract and replace URLs with links, except for "watch" and "shop" prefixed ones
        const urlRegex = /(https?:\/\/[^\s]+|www\.[^\s]+)/g;
        let formattedDescription = (readMore ? cleanedDescription : truncatedText).replace(urlRegex, (url) => {
            // Ensure URLs starting with "www" are prefixed with "https://"
            const href = url.startsWith('www') ? `https://${url}` : url;
            return `<a href="${href}" target="_blank">🔗</a>`;
        });

        // Preserve newlines by splitting the description into lines and rendering each line with a <br /> tag
        return formattedDescription.replace(/\n/g, "<br />");
    }, [readMore, cleanedDescription, truncatedText]);

    const formattedDescription = useMemo(() => fn(description), [description, fn]);

    return {needsReadMore, readMore, setReadMore, formattedDescription};
}