import React, { useContext, useEffect, useState, useMemo, memo } from "react";
import Navbar from "./blocks/Navbar.js";
import { Box, Tabs, Tab, Typography } from "@mui/material";
import { useNavigate, useSearchParams, useLocation } from "react-router-dom";
import ActionCard from "./blocks/ActionCard.js";
import Event from "./blocks/Event.js";
import Hero from "./blocks/Hero.js";
import TagsCollection from "./blocks/tags/TagsCollection.js";
import LoadingAnimation from "./blocks/LoadingAnimation.js";
import { AuthContext } from "src/utils/AuthContext.js";
import { getPageRoute } from "src/services";
import InfiniteScrollList from "./blocks/InfiniteScrollList.js";
import { useTrendingContent, TAGS_SIMPLE_VERSION } from "src/utils/useTrendingContent.js";
import { revealNavbar } from "src/utils/Utils.js";
import RecommendedCalendars from "./blocks/RecommendedCalendars.js";
import { CalendarSection } from "./blocks/CalendarSection.js";
import SearchBar from "./blocks/SearchBar.js";
import { Helmet } from 'react-helmet';
import onboardingTags from "src/onboarding-tags.json";

function Home() {
  const { user, checkSignIn } = useContext(AuthContext);
  const [searchParams] = useSearchParams();
  let { tab } = searchParams;
  tab = tab || new URLSearchParams(window.location.search).get("tab") || "discover";
  const [activeTab, setActiveTab] = useState(tab);
  if (!tab || activeTab) window.history.replaceState({}, "", `?tab=${activeTab}`);
  const [needRefresh, setNeedRefresh] = useState(false);
  const navigate = useNavigate();
  const isUser = Boolean(user);
  const trendingTags = useMemo(() => Object.values(onboardingTags).flat(), []);
  const {
    refetchUserEvents,
    addedCalendars,
    setAddedCalendars,
    isAddedLoading,
    upcomingAddedEvents,
    initialLoad, setInitialLoad,
    isTrendingLoading,
    tagsFilter,
    setTagsFilter,
    isStaffPicks,
    subscriberAZCalendars,
    leaderboardCalendars,
    isLoadingCalendarsByTags,
    relatedTags,
    hasMoreSearchByTagsPages,
    fetchSearchByTagsNextPage,
  } = useTrendingContent();
  const [eventIdSubscribed, setEventIdSubscribed] = useState(null);
  const subscribed = JSON.parse(localStorage.getItem("subscribed"));
  const [showRecommendedCalendars, setShowRecommendedCalendars] = useState(false);
  const location = useLocation();
  const recommendedCalendarsState = location.state ? location.state.recommendedCalendars ?? null : null;
  const staffPicksCalendars = isStaffPicks ? subscriberAZCalendars : null;

  // State to track custom tags
  const [customUrlTags, setCustomUrlTags] = useState(() => {
    const storedTags = localStorage.getItem('customUrlTags');
    return storedTags ? JSON.parse(storedTags) : [];
  });

  // Extract tag from URL if present
  const urlTag = useMemo(() => {
    const tagMatch = location.pathname.match(/\/tag\/(.+)/);
    return tagMatch ? decodeURIComponent(tagMatch[1]) : null;
  }, [location.pathname]);

  // Get default set of trending tags
  const trendingCalendarTags = useMemo(() => {
    if (!TAGS_SIMPLE_VERSION) {
      return [...new Set(["Staff Picks", ...trendingTags])];
    } else {
      return [...new Set(trendingTags)]
    }
  }, [trendingTags]);

  // Store the URL tag in localStorage and update state
  useEffect(() => {
    if (urlTag && !trendingCalendarTags.includes(urlTag)) {
      setTagsFilter([urlTag]);
      // Update state with the new tag if it's not already in the list
      if (!customUrlTags.includes(urlTag)) {
        const newCustomTags = [urlTag, ...customUrlTags];
        setCustomUrlTags(newCustomTags);
        localStorage.setItem('customUrlTags', JSON.stringify(newCustomTags));
      }
    }
  }, [urlTag, trendingCalendarTags, customUrlTags]);

  // Handles selecting Trending or Added events tab.
  const handleTabChange = async (event, key) => {
    revealNavbar();
    if (needRefresh) {
      setInitialLoad(true)
      refetchUserEvents()
      setNeedRefresh(false)
    }
    setActiveTab(key);
  };

  useEffect(() => {
    checkSignIn(true, null);
  }, []);

  useEffect(() => {
    if (subscribed && !isAddedLoading) {
      setAddedCalendars((prev) => {
        return [...prev, subscribed];
      })
      setShowRecommendedCalendars(true);
      localStorage.removeItem("subscribed");
    }
  }, [subscribed, setAddedCalendars, isAddedLoading])

  const onSubscribeAction = (eventId, subscribed) => {
    setNeedRefresh(true)
    setEventIdSubscribed(subscribed ? eventId : null);
  }

  const actionCardSx = { top: { xs: `calc(25vh + 60px + ${isUser ? "90px" : "0px"})`, sm: `calc(25vh + 80px + ${isUser ? "90px" : "0px"})` }, py: { sm: "80px" } };
  const tagCalendarsListSx = { borderTop: "none", "&>div": { pt: 0 } }
  const CalendarsTagNotFound = () => (
    <ActionCard
      title="No calendars found"
      description="There are no calendars associated with this tag."
      primaryButtonCTA="Search for calendars"
      primaryAction={() => navigate(getPageRoute("chat", "SEARCH"))}
      iconName="calendar_month"
      sx={{
        position: "static",
        mx: "auto",
        transform: "none",
        mt: { xs: 8, sm: 10 },
        pb: { xs: 16, sm: "80px" }
      }}
    />
  )

  return (
    <div>
      <Helmet>
        <title>Stanza - Discover and add events to your calendar</title>
        <meta name="description" content="We're an AI-powered platform for creating, discovering & subscribing to community calendars. Find events and calendars across sports, TV, entertainment, local events and more." />
      </Helmet>
      <Navbar user={user} />
      <Box sx={{ margin: "auto", maxWidth: { xs: '100%', sm: "600px" }, p: { xs: 2, md: "1rem 2rem" }}}>
        <Hero user={user}></Hero>
        <Tabs id="home-tabs" data-testid="home-tabs" value={activeTab} onChange={handleTabChange} variant={"fullWidth"} sx={{ zIndex: 10 }} >
          <Tab label="Discover" value="discover" disableRipple />
          <Tab label="My Events" value="myevents" disableRipple />
        </Tabs>
        <Box role="tabpanel" hidden={activeTab !== 'discover'}>
          {!isTrendingLoading && !initialLoad ? (
            <>
              {showRecommendedCalendars && (
                <RecommendedCalendars sourceCalendars={addedCalendars} onSubscribeAction={null} displayMode={user?.hasValidGoogleConnection ? "addedViaGoogleConnection" : "added"} />
              )}
              {recommendedCalendarsState && (
                <RecommendedCalendars sourceCalendars={[recommendedCalendarsState.calendar]} onSubscribeAction={null} displayMode={recommendedCalendarsState.displayMode} />
              )}
              <SearchBar initialFocus={true} />
              <TagsCollection
                trendingCalendarTags={
                  // Add the custom URL tags if they exist and are not already in the list
                  customUrlTags.length > 0
                    ? [...customUrlTags.filter(tag => !trendingCalendarTags.includes(tag)), ...trendingCalendarTags]
                    : trendingCalendarTags
                }
                tagsFilter={tagsFilter}
                setTagsFilter={setTagsFilter}
                isStaffPicks={isStaffPicks}
                isLoadingCalendarsByTags={isLoadingCalendarsByTags}
                relatedTags={relatedTags}
                sx={{ mt: 3 }}
              />
              {!TAGS_SIMPLE_VERSION && staffPicksCalendars && (
                <>
                  <Typography variant="h2" sx={{ mt: 4, mb: 2 }}>
                    Staff Picks
                  </Typography>
                  {subscriberAZCalendars.length ? (
                    <CalendarSection id="StaffPickCalendars" infiniteScrollList={true} calendarSection={{ calendars: staffPicksCalendars }} onSubscribeAction={onSubscribeAction} setAddedCalendars={setAddedCalendars} setShowRecommendedCalendars={setShowRecommendedCalendars} sx={tagCalendarsListSx} fetchSearchByTagsNextPage={fetchSearchByTagsNextPage} hasMoreSearchByTagsPages={hasMoreSearchByTagsPages} />
                  ) : (
                    <CalendarsTagNotFound />
                  )}
                </>
              )}
              {isLoadingCalendarsByTags && (
                <LoadingAnimation sx={{ p: 0 }} />
              )}
              {(TAGS_SIMPLE_VERSION || !isStaffPicks) && leaderboardCalendars && leaderboardCalendars.length > 0 && !isLoadingCalendarsByTags && (
                <>
                  <Typography variant="h2" sx={{ mt: 3, mb: 2 }}>
                    Leaderboard
                  </Typography>
                  <CalendarSection id="Leaderboard" calendarSection={{ calendars: leaderboardCalendars }} onSubscribeAction={onSubscribeAction} setAddedCalendars={setAddedCalendars} setShowRecommendedCalendars={setShowRecommendedCalendars} sx={tagCalendarsListSx} fetchSearchByTagsNextPage={fetchSearchByTagsNextPage} />
                </>
              )}
              {(TAGS_SIMPLE_VERSION || !isStaffPicks) && subscriberAZCalendars && !isLoadingCalendarsByTags && (
                <>
                  {subscriberAZCalendars.length ? (
                    <>
                      <Typography variant="h2" sx={{ mt: 3, mb: 2 }}>
                        A-Z
                      </Typography>
                      <CalendarSection id="AZCalendars" infiniteScrollList={true} calendarSection={{ calendars: subscriberAZCalendars }} onSubscribeAction={onSubscribeAction} setAddedCalendars={setAddedCalendars} setShowRecommendedCalendars={setShowRecommendedCalendars} sx={tagCalendarsListSx} fetchSearchByTagsNextPage={fetchSearchByTagsNextPage} hasMoreSearchByTagsPages={hasMoreSearchByTagsPages} />
                    </>
                  ) : (
                    <CalendarsTagNotFound />
                  )}
                </>
              )}
            </>
          ) : (isTrendingLoading || initialLoad) && (
            <LoadingAnimation />
          )}
        </Box>
        <Box role="tabpanel" hidden={activeTab !== 'myevents'}>
          {user ? (
            (isAddedLoading || initialLoad) ? (
              <LoadingAnimation />
            ) : upcomingAddedEvents?.length > 0 ? (
              <InfiniteScrollList
                threshold={700}
                id="myevents"
              >
                {upcomingAddedEvents.map((event, index) => (
                  <Event
                    calendar={event.calendar}
                    type="added"
                    key={event.event.jCal[1].find((prop) => prop[0] === "uid")[3]}
                    event={event.event}
                    calendarId={event.calendarId}
                    showUpload={false}
                    onSubscribeAction={() => refetchUserEvents()}
                    position={`added-${index}`}
                  />
                ))}
              </InfiniteScrollList>
            ) : (
              <ActionCard
                title="No events"
                description="You haven't added any calendars yet."
                testid="user-added-no-events"
                primaryButtonCTA="Discover Calendars"
                primaryAction={() => setActiveTab("discover")}
                iconName="event"
                sx={actionCardSx}
              />
            )
          ) : (
            <ActionCard
              title="You're not signed in"
              description="You'll need to sign in to see events you've added."
              testid="guest-need-to-sign"
              primaryButtonCTA="Sign in"
              primaryAction={() => navigate(getPageRoute("auth", "SIGNIN"))}
              iconName="account_circle_off"
              sx={actionCardSx}
            />
          )}
        </Box>
      </Box>
    </div >
  );
}

export default memo(Home);
