import { useState, useCallback, useEffect } from "react";
import { useDimensions } from "src/utils/useDimensions.js"
import { extractImageColors, fetchAndCacheImage } from "src/utils/Utils.js";
import {
    Box,
    Skeleton,
} from "@mui/material";

function TeamLogos({ team1Logo, team2Logo, alt, recommendedCalendar = false, handleImageError, ...props }) {
    const [bgGradient, setBgGradient] = useState("")
    const [loadingImg1, setLoadingImg1] = useState(true);
    const [loadingImg2, setLoadingImg2] = useState(!!team2Logo);
    const [uriImg1, setUriImg1] = useState("");
    const [uriImg2, setUriImg2] = useState("");
    const loading = loadingImg1 || loadingImg2;
    const { isMediumScreen } = useDimensions();
    const [topBarColor, setTopBarColor] = useState("")
    const [bottomBarColor, setBottomBarColor] = useState("")

    useEffect(() => {
        const fn = async () => {
            try {
                const uri1 = await fetchAndCacheImage(team1Logo, setUriImg1);
                if (team2Logo) {
                    const uri2 = await fetchAndCacheImage(team2Logo, setUriImg2);
                    const { dark: darkLeft } = await extractImageColors(uri1)
                    const { dark: darkRight } = await extractImageColors(uri2)
                    setTopBarColor(darkLeft)
                    setBottomBarColor(darkRight)
                    setBgGradient(`linear-gradient(110deg, ${darkLeft}, ${darkRight})`)
                } else {
                    const { dark: darkLeft } = await extractImageColors(uri1)
                    setBgGradient(`${darkLeft}`)
                }
                setLoadingImg1(false);
                setLoadingImg2(false);
            } catch (error) {
                console.error(error);
                handleImageError();
            }
        }
        fn();
    }, [])

    if (!team1Logo || !team2Logo) return null;

    const filterImg = "drop-shadow(2px 2px 7px rgba(0, 0, 0, 0.6)"
    const ImgStyles = { width: 'auto', height: '70px', maxWidth: '70px', minWidth: '70px', transform: "scale(1.1)", objectFit: "contain", filter: filterImg }

    const imgMarginRight = {
        'true': "30%",
        'false': isMediumScreen ? "30%" : "40%"
    }[JSON.stringify(recommendedCalendar)]

    return (
        <>
            {loading && (<Skeleton id="img-loader" animation="wave" variant="rectangular" sx={{ borderRadius: "4px" }} width={props.width} height={Number(props.height.split('px')[0])} />)}
            <Box
                display={loading ? 'none' : 'flex'}
                justifyContent="center"
                alignItems="center"
                bgcolor={'#f0f0f0'} // Set the background color
                border={`1px solid #d0d0d0`} // Set the border color
                borderRadius="8px" // Rounded corners
                sx={{ background: bgGradient, maxWidth: "-webkit-fill-available", position: 'relative', overflow: 'hidden' }}
                {...props}
            >
                <img src={uriImg1} id="team-logo" alt={alt} style={{ marginRight: imgMarginRight, ...ImgStyles }} />
                <hr style={{ position: 'absolute', top: "auto", left: "auto", transform: "translateY(-50%) rotate(110deg)", width: Number(props.height.split('px')[0]) * 1.25, height: "3px", border: "1px solid white", background: `linear-gradient(90deg, ${topBarColor}, ${bottomBarColor})` }} />
                <img src={uriImg2} id="team-logo" alt={alt} style={{ ...ImgStyles }} />
            </Box>
        </>
    );
}

export default TeamLogos;