import { useState, useRef, useEffect } from "react";
import { useProfileImage } from "src/utils/useProfileImage.js";

export const useDisplayImage = ({
  type = "RecommendedCalendar",
  calendar,
  homeTeamLogo,
  awayTeamLogo,
  eventImageUrl,
  background,
}) => {
  const profileImage = useProfileImage({
    picture:
      (calendar.picture ||
        `${process.env.REACT_APP_CLOUD_STORAGE_IMAGE_URL}/${calendar.handle}-profileImage.png`) ??
      null,
  });

  const [loading, setLoading] = useState(true);
  const imagesSrcRef = useRef([]);

  // Determine which image to display - simplified priority order
  const homeTeamLogoChecked = homeTeamLogo
    ?.split("/")
    .at(-1)
    .includes(calendar.name)
    ? homeTeamLogo
    : null;

  const [displayImage, setDisplayImage] = useState(() => {
    if (type === "RecommendedCalendar") {
      if (profileImage) imagesSrcRef.current.push(profileImage);
      if (homeTeamLogoChecked) imagesSrcRef.current.push(homeTeamLogoChecked);
      if (eventImageUrl) imagesSrcRef.current.push(eventImageUrl);
      if (background) imagesSrcRef.current.push(background);
    } else if (type === "Event") {
      if (eventImageUrl) imagesSrcRef.current.push(eventImageUrl);
      if (homeTeamLogo && awayTeamLogo) imagesSrcRef.current.push(homeTeamLogo);
      if (background) imagesSrcRef.current.push(background);
      if (profileImage) imagesSrcRef.current.push(profileImage);
    }
    return imagesSrcRef.current.shift() || null;
  });

  // Set loading to false after a short delay
  useEffect(() => {
    // If we have an image, wait a moment for it to load
    const timer = setTimeout(() => setLoading(false), 300);
    return () => clearTimeout(timer);
  }, [displayImage]);

  const handleImageError = () => {
    if (imagesSrcRef.current.length === 0) return setDisplayImage(null);
    const nextImage = imagesSrcRef.current.shift();
    setDisplayImage(nextImage);
  };

  return {
    displayImage,
    setDisplayImage,
    loading,
    handleImageError,
  };
};
