import React, { useState, useEffect, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Box, Typography, Button } from "@mui/material";
import axios from "axios";
import Navbar from "./blocks/Navbar.js";
import DeleteCalendarDialog from "./blocks/dialogs/DeleteCalendarDialog.js";
import DeleteGroupDialog from "./blocks/dialogs/DeleteGroupDialog.js";
import ImportOrEditForm from "./blocks/ImportOrEditForm.js";
import { getApiRoute, getPageRoute, useGetCalendar } from "src/services";
import { validateCalendar } from "src/utils/Utils.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import Footer from "./blocks/Footer.js";
import { Helmet } from 'react-helmet';
import { useMutation } from "@tanstack/react-query";
import { AuthContext } from "src/utils/AuthContext.js";
import { useDeleteCalendarGroup } from "src/services/mutations/useDeleteCalendarGroup.js";

function CalendarSettings({ user }) {
  const location = useLocation();
  const { calendar } = location.state;
  const typeGroup = calendar.calendars;

  const [currentCalendar, setCalendar] = useState(calendar);
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [link, setLink] = useState("");
  const [tags, setTags] = useState([]);
  const [openCalendar, setOpenCalendar] = useState(false);
  const [openGroup, setOpenGroup] = useState(false);
  const [deleteText, setDeleteText] = useState("");
  const { calendarId, handle } = useParams(); // get the handle & calendarID from the URL
  const [urlMessage, setUrlMessage] = useState("");
  const [validCalendarMessage, setValidCalendarMessage] = useState("");
  const [deleteMessage, setDeleteMessage] = useState("");
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
  const { refetchAuth } = useContext(AuthContext);

  const navigate = useNavigate();
  const { refetch: refetchCalendar } = useGetCalendar(
    { handle, calendarId: !typeGroup ? calendarId : null },
    {
      onSuccess: (data) => {
        if (!data) return;
        if (data.handle !== user?.handle) {
          // navigate the user to the page that shows the webcal contents
          navigate(
            getPageRoute("calendar", "ALL_EVENTS", {
              handle,
            })
          );
        }
        setCalendar(data);
        setName(data.name);
        setDescription(data.description);
        setTags(data.tags);
        setLink(data.link);
      },
    }
  );

  // Mark calendar deleted from user's createdCalendars array or delete the group
  const { mutateAsync: handleDeleteMutation, isLoading: isLoadingDelete } = useDeleteCalendarGroup()

  const handleDelete = async (event) => {
    event.preventDefault();
    if (deleteText !== "DELETE") {
      setDeleteMessage("Please check for typos.");
      return;
    }
    await handleDeleteMutation({
      typeGroup,
      calendar
    });
  }

  // Open delete dialog box
  const handleClickOpen = (event) => {
    event.preventDefault();
    if (typeGroup) {
      setOpenGroup(true);
    } else {
      setOpenCalendar(true);
    }
  };

  // Close delete dialog box
  const handleClose = (event) => {
    event.preventDefault();
    setDeleteText("");
    if (typeGroup) {
      setOpenGroup(false);
    } else {
      setOpenCalendar(false);
    }
  };

  // On page load
  useEffect(() => {
    if (!currentCalendar) {
      refetchCalendar();
    } else {
      setName(calendar.name);
      setDescription(calendar.description);
      setTags(calendar.tags);
      setLink(calendar.link);
    }
  }, []);

  // Update the tags object as new tags are checked or unchecked
  const handleTagChange = (tagName, checked) => {
    console.log("tags " + tags);
    setTags((prevTags) => {
      let newTags = prevTags.slice(); // create a copy of the tags array
      if (checked && !newTags.includes(tagName)) {
        newTags.push(tagName);
      } else if (!checked && newTags.includes(tagName)) {
        newTags = newTags.filter((tag) => tag !== tagName);
      }
      setCalendar((prevCalendar) => ({ ...prevCalendar, tags: newTags }));
      return newTags;
    });
  };

  // Save changes after hitting "Save" button in UI
  const { mutateAsync: handleSave, isLoading } = useMutation(async () => {
    try {
      if (typeGroup) {
        await axios.post(
          getApiRoute("user", "UPDATE_GROUP"),
          {
            groupId: calendar._id,
            name: name,
            description: description,
            tags: tags,
          },
          { withCredentials: true }
        );
        setCalendar((prevCalendar) => ({ ...prevCalendar, tags: tags }));
        showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Group saved successfully.', 'success');
        setTimeout(() => {
          navigate(
            `${getPageRoute("calendar", "CALENDAR_EVENTS", {
              handle,
              calendarId,
            })}?timestamp=${new Date().getTime()}`
          );
        }, 2000)
      } else {
        // Validate webcal link format for non-scraped links
        if (link !== "scraped") {
          const validWebcal =
            link.startsWith("http") || link.startsWith("webcal");
          if (!validWebcal) {
            setUrlMessage("Webcal link must start with webcal:// or http://.");
            return;
          }
          // Validate webcal link
          const resolve = await validateCalendar(link);
          if (!(resolve?.status >= 200 && resolve?.status < 300)) return setValidCalendarMessage(resolve.error);
        }

        await axios.patch(
          getApiRoute("calendar", "UPDATE_CALENDAR", {
            handle,
            calendarId,
          }),
          {
            link: link,
            name: name,
            description: description,
            tags: tags,
          },
          { withCredentials: true }
        );
        setCalendar((prevCalendar) => ({ ...prevCalendar, tags: tags }));
        showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Calendar saved successfully.', 'success');
        // Introduce a delay before navigating
        setTimeout(() => {
          navigate(
            `${getPageRoute("calendar", "CALENDAR_EVENTS", {
              handle,
              calendarId,
            })}?timestamp=${new Date().getTime()}`
          );
        }, 2000);
      }
    } catch (error) {
      console.error(error);
      showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, '⚠️ Error saving. Please try again.', 'error');
    }
  });

  return (
    <div style={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
      <Helmet>
        <title>Calendar Settings - Stanza</title>
        <meta name="description" content="Edit your calendar settings. Change the name, description, tags, and webcal link." />
      </Helmet>
      <Navbar user={user} />
      <Box sx={{ margin: "auto", marginTop: '0', maxWidth: { xs: '100%', sm: "600px" }, p: 2 }}>
        <Typography as="h1" variant="title" sx={{ mb: 3, mt: { md: 3 }, fontWeight: "600" }}>
          {typeGroup ? "Edit your group" : "Edit your calendar"}
        </Typography>
        <ImportOrEditForm
          name={name}
          setName={setName}
          description={description}
          setDescription={setDescription}
          link={link}
          setLink={setLink}
          typeGroup={typeGroup}
          urlMessage={urlMessage}
          validCalendarMessage={validCalendarMessage}
          tags={tags}
          handleTagChange={handleTagChange}
          handleSave={handleSave}
          calendars={calendar.calendars}
          isLoading={isLoading}
        />
        <Button
          data-testid={typeGroup ? "group-edit-delete-button" : "calendar-edit-delete-button"}
          size="large"
          type="delete"
          color="error"
          onClick={handleClickOpen}
          sx={{ float: "right", mb: 2, mr: 2 }}
        >
          Delete
        </Button>
        <DeleteCalendarDialog
          open={openCalendar}
          handleClose={handleClose}
          handleDelete={handleDelete}
          isLoading={isLoadingDelete}
          deleteText={deleteText}
          deleteMessage={deleteMessage}
          setDeleteText={setDeleteText}
        />
        <DeleteGroupDialog
          open={openGroup}
          handleClose={handleClose}
          handleDelete={handleDelete}
          isLoading={isLoadingDelete}
          deleteText={deleteText}
          deleteMessage={deleteMessage}
          setDeleteText={setDeleteText}
        />
      </Box>
      <Footer />
    </div>
  );
}

export default CalendarSettings;
