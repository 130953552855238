import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useTheme } from "@emotion/react";
import PermContact from "src/assets/svg/permcontact.js";
import { Box, Typography, Button, Divider, Link } from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import { useMutation } from "@tanstack/react-query";
import {
  generateGCalReminder,
  getCalendarApiUrl,
  getWebcalFormat,
  handleOpenWebcal,
} from "src/utils/Utils.js";
import { getPageRoute } from "src/services";
import { customEvent } from "src/utils/gtag.js";
import ConfirmationScreen from "../ConfirmationScreen.js";
import UAParser from "ua-parser-js";
import InstructionsLayout from "../InstructionsLayout.js";
import RedirectionMessage from "src/components/blocks/RedirectionMessage.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";

const GoogleCalendarExperience = () => {
  const [step, setStep] = useState("MainSection");

  const location = useLocation();
  const calendar = location.state ? location.state.calendar ?? null : null;
  const theme = useTheme();
  const navigate = useNavigate();
  const uaParser = new UAParser();
  const parserResults = uaParser.getResult();

  const isDesktop = !parserResults.device.type;

  useEffect(() => {
    if (!calendar) {
      navigate(getPageRoute("home", "HOME"));
    }
  }, [calendar]);

  return (
    <InstructionsLayout
      sx={{
        alignItems: step !== "subscribeOnDesktop" ? "center" : "start",
        textAlign: step !== "subscribeOnDesktop" ? "center" : "start",
      }}
      nav="home"
    >
      {
        {
          MainSection: <MainSection isDesktop={isDesktop} setStep={setStep} />,
          downloadReminder: (
            <DownloadReminder
              isDesktop={isDesktop}
              calendar={calendar}
              setStep={setStep}
            />
          ),
        }[step]
      }
    </InstructionsLayout>
  );
};

const MainSection = ({ isDesktop, setStep }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useContext(AuthContext).user;

  const handleDownloadReminder = () => {
    setStep("downloadReminder");
  };

  return (
    <>
      <PermContact />
      <Typography variant={"h2"} sx={{ mt: 1.5 }}>
        {isDesktop ? (
          <>
            Events will not appear in your <br></br> Google Calendar app on
            mobile
          </>
        ) : (
          "Google Calendar App doesn't support new calendar subscriptions"
        )}
      </Typography>
      <Typography variant={"subtitle2"} sx={{ mt: 1.5 }}>
        {isDesktop ? (
          <>
            Google requires you to manually sync <br></br>new calendar
            subscriptions on mobile.
          </>
        ) : (
          "You can only subscribe to new calendars on the web and need to manually sync on mobile."
        )}
      </Typography>
      <Button
        variant="contained"
        size="grown"
        sx={{ mt: 6, width: "330px" }}
        onClick={() => {
          customEvent({
            name: "nav_google_calendar_steps_experience_page",
            category: "GoogleCalendarExperience",
            label: "Navigate to Google Calendar Steps from Experience Page",
            data: {
              user_id: user._id,
              user_email: user.email,
              user_handle: user.handle ?? "no_handle",
            },
          });
          navigate(getPageRoute("help", "SUBSCRIBING_GOOGLE_CALENDAR_STEPS"));
        }}
      >
        CONNECT GOOGLE CALENDAR
      </Button>
      <Typography
        variant={"subtitle2"}
        sx={{
          mt: 2,
          width: "330px",
        }}
      >
        Break free from Google's limitations and <br></br>add events across{" "}
        <u>all your devices</u> seamlessly.
      </Typography>
      <Box sx={{ width: "100%", maxWidth: "330px" }}>
        <Divider sx={{ mt: 3, mb: 3.5 }}>
          <Typography variant="subtitle2" color="text.secondary">
            or
          </Typography>
        </Divider>
      </Box>

      <Button
        variant="outlined"
        size="grown"
        sx={{ width: "330px" }}
        onClick={handleDownloadReminder}
      >
        SUBSCRIBE ON DESKTOP & SYNC
      </Button>
      <Typography
        variant={"subtitle2"}
        sx={{
          mt: 2,
          width: "330px",
        }}
      >
        Subscribe on desktop and <u>manually sync</u> with your Google Calendar
        app on every mobile device.
      </Typography>
    </>
  );
};

const DownloadReminder = ({ isDesktop, calendar, setStep }) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();
  const calendarApiUrl = getCalendarApiUrl({ calendar, user });

  const navigate = useNavigate();

  const handleDownloadGCalReminder = async () => {
    if (!isDesktop) {
      customEvent({
        name: "download_gcal_reminder",
        category: "GoogleCalendarExperience",
        label: "Download Google Calendar Reminder Click",
        data: {
          user_id: user._id,
          user_email: user.email,
          user_handle: user.handle ?? "no_handle",
        },
      });

      const stanzaLink = `${calendar?.stanzaLink}/${user?._id}.ics`;
      const icsContent = generateGCalReminder(calendar?.name, stanzaLink);
      const blob = new Blob([icsContent], {
        type: "text/calendar;charset=utf-8",
      });
      const url = URL.createObjectURL(blob);

      // Create a temporary link to trigger the download
      const link = document.createElement("a");
      link.href = url;
      link.download = `Sync_${calendar?.name}_GCal_Reminder.ics`;

      // Append to the document and trigger the click
      document.body.appendChild(link);
      link.click();

      // Clean up
      document.body.removeChild(link);
      URL.revokeObjectURL(url);
    }

    return true;
  };

  const {
    mutateAsync: downloadGCalReminder,
    data: isDone,
    isLoading,
  } = useMutation(handleDownloadGCalReminder);

  useEffect(() => {
    if (isDone) {
      navigate(getPageRoute("home", "HOME"), {
        state: {
          recommendedCalendars: {
            calendar,
            displayMode: isDesktop
              ? "added"
              : "downloadGCalReminderConfirmation",
          },
        },
      });
      setTimeout(() => {
        showSnackBar(
          setSnackbarOpen,
          setSnackbarMessage,
          setSnackbarSeverity,
          <RedirectionMessage
            redirectionCallback={() =>
              handleOpenWebcal(
                user,
                getWebcalFormat(calendarApiUrl),
                calendar,
                navigate
              )
            }
          />,
          "success"
        );
      }, 1000);
    }
  }, [
    isDone,
    navigate,
    calendar,
    isDesktop,
    showSnackBar,
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    user,
    calendarApiUrl,
  ]);

  return (
    <ConfirmationScreen
      title={
        isDesktop ? "You'll need to re-sync on mobile." : "Download a reminder"
      }
      subcopy={
        isDesktop ? (
          <>
            By continuing, you acknowledge that events will not automatically
            appear on your mobile devices. <br></br>
            <br></br> You will need to{" "}
            <Link
              href="https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/editor/google-calendar"
              taget="_blank"
            >
              re-sync
            </Link>{" "}
            for them to appear.
          </>
        ) : (
          "When you're next on your desktop, open the calendar reminder with instructions on how to finish subscribing."
        )
      }
      buttonText="CONTINUE"
      buttonClickHandler={downloadGCalReminder}
      loading={isLoading}
      backClickHandler={() => setStep("MainSection")}
    />
  );
};

export default GoogleCalendarExperience;
