import { useMemo, useState, useEffect } from "react";
import DefaultImageBasketball from "./DefaultImageBasketball.js";
import { Box, Skeleton } from "@mui/material";
import {
  extractImageColors,
  fetchAndCacheImage,
  getEventUniqueNumber,
} from "src/utils/Utils";
import { useQuery } from "@tanstack/react-query";

const DefaultImageAvatar = ({
  userAvatar,
  bgGradients,
  loading,
  eventUniqueNumber,
  ...props
}) => {
  const filterImg = "drop-shadow(2px 2px 7px rgba(0, 0, 0, 0.6)";
  const ImgStyles = {
    width: "auto",
    height: "70px",
    maxWidth: "70px",
    minWidth: "70px",
    transform: "scale(1.1)",
    objectFit: "contain",
    filter: filterImg,
  };
  // this is to assign the same gradient to the same event every time
  const gradientAsignation = eventUniqueNumber
    ? parseInt(eventUniqueNumber) % 6
    : 0;

  return (
    <Box
      display={loading ? "none" : "flex"}
      justifyContent="center"
      alignItems="center"
      bgcolor={"#f0f0f0"} // Set the background color
      border={`1px solid #d0d0d0`} // Set the border color
      borderRadius="8px" // Rounded corners
      sx={{
        background: bgGradients?.[gradientAsignation],
        maxWidth: "-webkit-fill-available",
        position: "relative",
        overflow: "hidden",
      }}
      {...props}
    >
      <img src={userAvatar} id="user-avatar" style={{ ...ImgStyles }} />
    </Box>
  );
};

const DefaultImage = ({ profileImage, ...props }) => {
  const [loading, setLoading] = useState(true);

  const {
    data: { userAvatar, bgGradients } = {
      userAvatar: null,
      bgGradients: null,
    },
    isError,
    refetch,
  } = useQuery([profileImage, "bgGradients-DefaultImage"], async () => {
    const userAvatar = await fetchAndCacheImage(profileImage, () => {});
    if (!userAvatar) {
      setLoading(false);
      return { userAvatar: false, bgGradients: null }; // no image, use basketball default image
    }
    const { dark, vibrant, lightVibrant, lightMuted, darkMuted } =
      await extractImageColors(userAvatar);
    setLoading(false);
    return {
      userAvatar,
      bgGradients: [
        `linear-gradient(270deg, ${vibrant}, ${lightMuted})`,
        `linear-gradient(315deg, ${vibrant}, ${darkMuted})`,
        `linear-gradient(270deg, ${lightMuted}, ${vibrant}, ${darkMuted})`,
        `linear-gradient(180deg, ${lightMuted}, ${vibrant})`,
        `linear-gradient(315deg, ${lightVibrant}, ${vibrant})`,
        `linear-gradient(315deg, ${vibrant}, ${vibrant})`,
      ],
    };
  });

  useEffect(() => {
    if (userAvatar || bgGradients) {
      setLoading(false); // we have in cache the image uri and gradients
    } else if (userAvatar === false) {
      setLoading(false); // no image, use basketball default
    } else if (isError) {
      setLoading(false); // render ImageBasketball
    }
  }, [bgGradients, userAvatar, isError]);

  const eventUniqueNumber = useMemo(
    () => getEventUniqueNumber(props.id, props.position),
    [props.id, props.position]
  );

  return (
    <>
      {loading && (
        <Skeleton
          id="img-loader"
          animation="wave"
          variant="rectangular"
          sx={{ borderRadius: "4px", position: "relative", zIndex: 1 }}
          width={props.width}
          height={Number(props.height.split("px")[0])}
        />
      )}

      {!userAvatar || isError ? (
        <DefaultImageBasketball
          loading={loading}
          eventUniqueNumber={eventUniqueNumber}
          {...props}
        />
      ) : (
        <DefaultImageAvatar
          loading={loading}
          eventUniqueNumber={eventUniqueNumber}
          userAvatar={userAvatar}
          bgGradients={bgGradients}
          {...props}
        />
      )}
    </>
  );
};

export default DefaultImage;
