import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import LoadingAnimation from "src/components/blocks/LoadingAnimation.js";
import { rollbar } from "src/utils/ErrorBoundary/index.js";
import { auth } from "src/firebase/firebase.js";

const StripeWrapper = ({ children }) => {
  const [stripe, setStripe] = useState(null);

  useEffect(() => {
    let isMounted = true;
    const loadStripeWithRetry = async (retries = 3, delay = 3000) => {
      let lastError = null;
      let stripeInstance = null;
      for (let attempt = 0; attempt < retries; attempt++) {
        try {
          stripeInstance = await loadStripe(
            process.env.REACT_APP_STRIPE_PUBLIC_KEY
          );
          if (stripeInstance && isMounted) {
            setStripe(stripeInstance);
            break;
          }
        } catch (error) {
          debugger;
          console.error(
            `Failed to load Stripe.js (attempt ${attempt + 1} of ${retries})`,
            error
          );
          lastError = error;
          if (attempt < retries - 1) {
            await new Promise((resolve) => setTimeout(resolve, delay));
          }
        }
      }
      if (!stripeInstance) {
        console.error("Failed to load Stripe.js after multiple attempts");
        const userSignedIn = Boolean(auth.currentUser);
        rollbar.error(
          "Failed to load Stripe.js after multiple attempts",
          lastError,
          {
            userSignedIn,
          }
        );
      }
    };

    loadStripeWithRetry();

    return () => {
      isMounted = false;
    };
  }, []);

  if (!stripe) {
    return children;
  }

  return <Elements stripe={stripe}>{children}</Elements>;
};

export default StripeWrapper;
