import { useNavigate, useLocation } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import { useTheme } from "@emotion/react";
import PermContact from "src/assets/svg/permcontact.js";
import { Box, Typography, Button, Divider } from "@mui/material";
import { AuthContext } from "src/utils/AuthContext.js";
import { getPageRoute } from "src/services";
import { customEvent } from "src/utils/gtag.js";
import ConfirmationScreen from "../ConfirmationScreen.js";
import InstructionsLayout from "../InstructionsLayout.js";
import RedirectionMessage from "src/components/blocks/RedirectionMessage.js";
import {
  getCalendarApiUrl,
  getWebcalFormat,
  handleOpenWebcal,
} from "src/utils/Utils.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";

const CalendarExperience = () => {
  const [step, setStep] = useState("MainSection");

  const location = useLocation();
  const calendar = location.state ? location.state.calendar ?? null : null;
  const theme = useTheme();
  const navigate = useNavigate();

  useEffect(() => {
    if (!calendar) {
      navigate(getPageRoute("home", "HOME"));
    }
  }, [calendar]);

  return (
    <InstructionsLayout
      sx={{
        alignItems: step !== "subscribeOnDesktop" ? "center" : "start",
        textAlign: step !== "subscribeOnDesktop" ? "center" : "start",
      }}
      nav="home"
    >
      {
        {
          MainSection: <MainSection setStep={setStep} />,
          confirmationScreen: (
            <ConfirmSection calendar={calendar} setStep={setStep} />
          ),
        }[step]
      }
    </InstructionsLayout>
  );
};

const MainSection = ({ setStep }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const user = useContext(AuthContext).user;

  const handleConfirmationScreen = () => {
    setStep("confirmationScreen");
  };

  return (
    <>
      <PermContact />
      <Typography variant={"h2"} sx={{ mt: 1.5 }}>
        Your calendar app updates silently <br></br>in the background.
      </Typography>
      <Typography variant={"subtitle2"} sx={{ mt: 1.5 }}>
        You won't know when your calendar last updated <br></br> and whether you
        have the latest events.
      </Typography>
      <Button
        variant="contained"
        size="grown"
        sx={{ mt: 6, width: "330px" }}
        onClick={() => {
          customEvent({
            name: "nav_other_calendar_steps_experience_page",
            category: "OtherCalendarExperience",
            label: "Navigate to Other Calendar Steps from Experience Page",
            data: {
              user_id: user._id,
              user_email: user.email,
              user_handle: user.handle ?? "no_handle",
            },
          });
          navigate(getPageRoute("help", "SUBSCRIBING_CALENDAR_STEPS"));
        }}
      >
        CONNECT YOUR CALENDAR
      </Button>
      <Typography
        variant={"subtitle2"}
        sx={{
          mt: 2,
          width: "330px",
        }}
      >
        Know <u>exactly</u> when your calendar last updated. <br></br>Force
        refresh if needed.
      </Typography>
      <Box sx={{ width: "100%", maxWidth: "330px" }}>
        <Divider sx={{ mt: 3, mb: 3.5 }}>
          <Typography variant="subtitle2" color="text.secondary">
            or
          </Typography>
        </Divider>
      </Box>
      <Button
        variant="outlined"
        size="grown"
        sx={{ width: "330px" }}
        onClick={handleConfirmationScreen}
      >
        STATUS QUO
      </Button>
      <Typography
        variant={"subtitle2"}
        sx={{
          mt: 2,
          width: "330px",
        }}
      >
        Hope for the best with silent updates and <br></br>
        <u>manually verify</u> that you have the latest events.
      </Typography>
    </>
  );
};

const ConfirmSection = ({ calendar, setStep }) => {
  const theme = useTheme();
  const { user } = useContext(AuthContext);
  const navigate = useNavigate();
  const calendarApiUrl = getCalendarApiUrl({ calendar, user });
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  const handleRedirection = () => {
    navigate(getPageRoute("home", "HOME"), {
      state: {
        recommendedCalendars: {
          calendar,
          displayMode: "added",
        },
      },
    });
    setTimeout(() => {
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        <RedirectionMessage
          redirectionCallback={() =>
            handleOpenWebcal(
              user,
              getWebcalFormat(calendarApiUrl),
              calendar,
              navigate
            )
          }
        />,
        "success"
      );
    }, 1000);
  };

  return (
    <ConfirmationScreen
      title={
        <>
          You won't know when your <br />
          calendar updates
        </>
      }
      subcopy={
        <>
        By continuing, you acknowledge that your calendar <br></br> may be out of sync with the latest events.
        </>
      }
      buttonText="CONTINUE"
      buttonClickHandler={handleRedirection}
      backClickHandler={() => setStep("MainSection")}
    />
  );
};

export default CalendarExperience;
