import { useLayoutEffect, useRef } from "react";
import { Avatar, Typography, Box, Button } from "@mui/material";
import { useProfileImage } from "src/utils/useProfileImage.js";
import { formatSubscribers } from "src/utils/Utils.js";
import Stanzalogo from "src/assets/svg/stanza-logo.js";

// Profile component oriented to show calendar information
export function ProfileCard({ creator, calendarName, handleAddToCalendar }) {
  const profileImage = useProfileImage(creator);
  const buttonRef = useRef(null);

  useLayoutEffect(() => {
    const button = buttonRef.current;
    if (!button || typeof handleAddToCalendar !== "string") return;
    if (button) {
      const stopEvents = [
        "mouseover",
        "mouseenter",
        "mousemove",
        "mouseout",
        "mouseleave",
        "click",
      ];
      stopEvents.forEach((eventName) => {
        button.addEventListener(
          eventName,
          (event) => event.stopPropagation(),
          true
        );
      });
    }
  }, []);

  return (
    <Box
      id="calendar-card-box"
      display="flex"
      flexDirection="column"
      alignItems="center"
      p={4}
    >
      <Avatar
        src={profileImage}
        id="avatar-profile-img"
        data-testid="avatar-profile-img"
        alt={creator?.name}
        sx={{ minWidth: 39, minHeight: 39 }}
      />
      <Typography
        variant="h2"
        id="avatar-calendar-name"
        data-testid="avatar-calendar-name"
        sx={{ mt: 1, textAlign: "center" }}
      >
        {calendarName}
      </Typography>
      {formatSubscribers(creator?.subscribers) && (
        <Typography
          variant="subtitle2"
          color="text.secondary"
          data-testid="avatar-calendar-count"
        >
          {formatSubscribers(creator?.subscribers)}
        </Typography>
      )}
      {typeof handleAddToCalendar === "string" ? (
        <Button
          ref={buttonRef}
          variant="contained"
          size="shrinked"
          component="div"
          sx={{ width: "176px", mt: 2 }}
        >
          <a
            href={handleAddToCalendar}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "auto",
              WebkitTextDecorationLine: "none",
              color: "#ffffff",
              height: "100%",
              display: "block",
              minWidth: "calc(100% + 32px)",
              textAlign: "center",
            }}
          >
            Add to Calendar
          </a>
        </Button>
      ) : (
        <Button
          variant="contained"
          size="shrinked"
          sx={{ width: "176px", mt: 2 }}
          onClick={handleAddToCalendar}
        >
          Add to Calendar
        </Button>
      )}
      <Box display="flex" alignItems="center" gap="6px" mt={2}>
        <Typography variant="caption" color="text.secondary">
          POWERED BY
        </Typography>
        <Stanzalogo
          width="68.5px"
          style={{ transform: "translateY(-0.5px)" }}
        />
      </Box>
    </Box>
  );
}
