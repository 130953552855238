import { useState } from "react";

export default function useClamp({ text = "", maxLength }) {
  const [readMore, setReadMore] = useState(false);

  function truncate(text, maxLength) {
    return text.length > maxLength
      ? text.substring(0, maxLength) + "..."
      : text;
  }

  const truncatedText = truncate(text, maxLength);
  const needsReadMore = text.length > maxLength;

  return { readMore, setReadMore, needsReadMore, truncatedText, truncate };
}
