import axios from "axios";
import { getApiRoute } from "src/services";
import { useMutation } from "@tanstack/react-query";

async function stripeCheckout({ type = "consumer_annual", successUrl, cancelUrl, freeTrial = false }) {
  return axios.post(
    getApiRoute("upgrade", "STRIPE_CHECKOUT"),
    { type, successUrl: successUrl, cancelUrl, freeTrial },
    { withCredentials: true }
  );
}

export function useStripeCheckout() {
  return useMutation(stripeCheckout);
}