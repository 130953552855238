import React, { useState, useEffect, memo } from "react";
import { useProfileImage } from "src/utils/useProfileImage.js";
import {
  List,
  ListItem,
  ListItemAvatar,
  Avatar,
  ListItemText,
  Typography,
  Box,
  Icon,
  Button,
} from "@mui/material";
import { useTheme } from "@emotion/react";
import SubscribeButton from "./SubscribeButton.js";
import { useGetCalendarEvents } from "src/services/queries/useGetCalendarEvents";
import {
  fetchAndCacheImage,
  extractImageColors,
  getUpcomingEvents,
  getPastEvents,
} from "src/utils/Utils";
import ical from "ical.js";

const CalendarAvatar = ({
  calendar,
  creator,
  setSelectedCalendar,
  handleSwitchCalendar,
}) => {
  const theme = useTheme();
  const { data: icalData } = useGetCalendarEvents({
    link: calendar?.stanzaLink?.replace("webcal://", "http://"),
  });
  const profileImageUrl = useProfileImage(creator);
  const [bgColor, setBgColor] = useState(null);
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    const getProfileImage = async () => {
      if (profileImageUrl) {
        const imageUrl = await fetchAndCacheImage(profileImageUrl);
        setProfileImage(imageUrl);
      }
    };
    const getEventImage = async () => {
      try {
        if (!icalData) return;

        const jcalData = ical.parse(icalData);
        const comp = new ical.Component(jcalData);
        const allEvents = [
          ...getUpcomingEvents(comp.getAllSubcomponents("vevent"), 50),
          ...getPastEvents(comp.getAllSubcomponents("vevent"), 50),
        ];

        // Find the first valid image in events
        let imageUrl;
        for (const event of allEvents) {
          const jCal = event.jCal;
          const eventId = jCal[1].find((prop) => prop[0] === "uid")?.[3];

          // List of possible image sources
          const potentialImages = [
            jCal[1].find((prop) => prop[0] === "x-homelogourl")?.[3],
            jCal[1].find((prop) => prop[0] === "x-backgroundurl")?.[3],
            eventId &&
              `${process.env.REACT_APP_CLOUD_STORAGE_IMAGE_URL}/${calendar.handle}-${eventId}-eventImage.png`,
          ].filter(Boolean);

          // Check each image sequentially
          for (const url of potentialImages) {
            try {
              imageUrl = await fetchAndCacheImage(url);
              break;
            } catch (error) {
              console.log(`Error fetching event image`, error);
            }
          }
          if (imageUrl) break;
        }
        if (imageUrl) {
            const colors = await extractImageColors(imageUrl);
            setBgColor(colors.vibrant);
        }
      } catch (error) {
        console.error("Error processing calendar image:", error);
      }
    };

    getProfileImage().catch(() => {
      getEventImage();
    });
  }, [icalData, calendar.handle, profileImageUrl]);

  const avatarStyle = {
    "& svg": {
      display: "none",
    },
    ...(profileImage
      ? {
          backgroundImage: `url(${profileImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          "& p": {
            filter: "drop-shadow(2px 2px 7px rgba(0, 0, 0, 0.6))",
          },
        }
      : {
          bgcolor: bgColor || theme.palette.avatarFallback,
        }),
  };

  return (
    <Box
      sx={{ position: "relative", width: "min-content", cursor: "pointer" }}
      onClick={() => handleSwitchCalendar(calendar)}
    >
      <Avatar sx={avatarStyle} />
      <Typography
        sx={{
          position: "absolute",
          left: "50%",
          top: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 2,
          pointerEvents: "none",
          fontWeight: "500",
          fontSize: "1rem",
          color: "white",
        }}
      >
        {calendar.name.charAt(0).toUpperCase()}
      </Typography>
      {profileImage && (
        <Box
          sx={{
            position: "absolute",
            zIndex: 1,
            top: 0,
            borderRadius: "100%",
            width: "100%",
            height: "100%",
            background: "linear-gradient(180deg, #000000, transparent)",
          }}
        />
      )}
    </Box>
  );
};

const CreatorCalendarsSuggestions = memo(
  ({ calendars = [], creator, setSelectedCalendar, sx }) => {
    const theme = useTheme();
    const [calendarView, setCalendarView] = useState(calendars.slice(0, 4));

    useEffect(() => {
      setCalendarView(
        calendarView.length === 4 ? calendars.slice(0, 4) : calendars
      );
    }, [calendars]);

    const handleSwitchCalendar = (calendar) => {
      setSelectedCalendar(calendar);
      window.scroll({
        top: 0,
        behavior: "auto",
      });
    };

    return (
      <>
        <style>
          {`
      #creator-calendars-suggestions + div > #event-card {
        border-top: none;
      }
      #creator-calendars-suggestions + div {
        margin-top: 0px;
      }
    `}
        </style>
        <Box
          id="creator-calendars-suggestions"
          sx={{
            display: "flex",
            flexDirection: "column",
            py: { xs: 3.5, md: 4.5 },
            pl: { xs: 0, md: 2 },
            borderTop: `1px solid ${theme.palette.text.tertiary}`,
            borderBottom: `1px solid ${theme.palette.text.tertiary}`,
            backgroundColor: theme.palette.background.paper,
            ...sx,
          }}
        >
          <Box sx={{ display: "flex", alignItems: "center", gap: 0.5 }}>
            <Icon
              baseClassName="material-symbols-outlined"
              sx={{
                fontWeight: "300",
                fontSize: "1.8rem",
              }}
            >
              calendar_month
            </Icon>
            <Typography variant="h2" data-testid="related-calendars-title">
              Other calendars
            </Typography>
          </Box>
          <List sx={{ p: 0, mt: { xs: 2, md: 3 } }}>
            {calendarView.map((calendar) => (
              <ListItem
                key={calendar._id}
                secondaryAction={
                  <SubscribeButton calendar={calendar} isEventCard={true} />
                }
                sx={{
                  "& .MuiListItemSecondaryAction-root": { right: 2 },
                }}
              >
                <ListItemAvatar>
                  <CalendarAvatar
                    calendar={calendar}
                    creator={creator}
                    setSelectedCalendar={setSelectedCalendar}
                    handleSwitchCalendar={handleSwitchCalendar}
                  />
                </ListItemAvatar>
                <ListItemText
                  sx={{ pr: "90px" }}
                  primary={
                    <Typography
                      onClick={() => handleSwitchCalendar(calendar)}
                      color="inherit"
                      underline="none"
                      variant="h5"
                      sx={{
                        ...theme.ellipsis(),
                        display: "block",
                        cursor: "pointer",
                      }}
                    >
                      {calendar.name}
                    </Typography>
                  }
                  secondary={
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ ...theme.ellipsis() }}
                    >
                      {calendar.description}
                    </Typography>
                  }
                />
              </ListItem>
            ))}
          </List>
          {calendarView.length < calendars.length && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: { xs: 1, md: 1.5 },
              }}
            >
              <Button
                variant="text"
                sx={{
                  textTransform: "none",
                  padding: 1,
                }}
                onClick={() => setCalendarView(calendars)}
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Icon
                    fontSize="medium"
                    sx={{ fontWeight: "300" }}
                    baseClassName="material-symbols-outlined"
                  >
                    visibility
                  </Icon>
                  <Typography variant="subtitle2">See More</Typography>
                </Box>
              </Button>
            </Box>
          )}
        </Box>
      </>
    );
  }
);

export default CreatorCalendarsSuggestions;
