import React, { useEffect, useRef } from "react";
import { getWindowProperty } from "../TimelineEmbedded.js";

const Ad = ({ placementName, alias, style }) => {
  const elRef = useRef(null);

  useEffect(() => {
    let placement;
    console.log("[PROSPER] add", placementName);

    try {
      getWindowProperty("__VM").push((admanager, scope) => {
        placement = scope.Config.get(placementName, alias).display(
          elRef.current
        );
      });
    } catch (error) {
      console.error("[PROSPER] Error initializing ad:", error);
    }

    return () => {
      try {
        getWindowProperty("__VM").push((admanager, scope) => {
          console.log("[PROSPER] removed", placementName);
          admanager.removePlacement(placement.instance());
        });
      } catch (error) {
        console.error("[PROSPER] Error cleaning up ad:", error);
      }
    };
  }, []);
  return <div style={style} ref={elRef}></div>;
};

export default Ad;

export const handleVenatusNewSession = () => {
  try {
    const vm = getWindowProperty("__VM") || [];
    vm.push(function (admanager, scope) {
      console.log("[PROSPER] New page session. out");
      scope.Instances.pageManager.on(
        "navigated",
        () => {
          // this should trigger every time you consider the content a "new page"
          scope.Instances.pageManager.newPageSession(false);
          console.log("[PROSPER] New page session.");
        },
        false
      );
    });
  } catch (error) {
    console.error("[PROSPER] Error initializing new session:", error);
  }
};
