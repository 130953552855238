import { useState, useEffect, useRef } from "react";
import axios from "axios";
import { getApiRoute } from "src/services";
import {
  getRedirectResult,
  connectAuthEmulator,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from "firebase/auth";
import { auth } from "../firebase/firebase.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { customEvent } from "./gtag.js";
import { capitalize } from "lodash";

export default function useAuth() {
  const [user, setUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, showSnackBar } = useSnackbarContext();
  const searchParams = new URLSearchParams(window.location.search);
  const email = searchParams.get("email");
  const hasSignedInRef = useRef({ status: false, providerName: null });

  async function getAuth() {
    try {
      const response = await axios.get(getApiRoute("auth", "GET_AUTH"), {
        withCredentials: true,
      });
      setUser(response.data);
    } catch (error) {
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getAuth();
  }, []);

  useEffect(() => {
    if (hasSignedInRef.current.status && user) {
      customEvent({
        name: `user_signed_in`,
        category: "Authorization",
        label: `Sign in with ${capitalize(hasSignedInRef.current.providerName)} Successfully`,
        data: {
          user_id: user._id,
          user_email: user.email,
          user_handle: user.handle ?? 'no_handle',
          provider: hasSignedInRef.current.providerName,
        },
      });
      hasSignedInRef.current.status = false;
    }
  }, [user]);

  const handleSignOut = async () => {
    try {
      await axios.post(
        getApiRoute("auth", "POST_SIGNOUT"),
        null,
        { withCredentials: true }
      );
      setUser(null);
    } catch (error) {
      console.error(error);
    } finally {
      setUser(null);
    }
  };

  useEffect(() => {
    const host = process.env.REACT_APP_FIREBASE_AUTH_EMULATOR_HOST;
    if (host) {
      connectAuthEmulator(auth, host);
    }
  }, [])

  const checkSignIn = async (withRedirect, resultFromPopup) => {
    try {
      let result = resultFromPopup;
      if (isSignInWithEmailLink(auth, window.location.href) && email) {
        try {
          result = await signInWithEmailLink(auth, email, window.location.href);
        } catch (err) {
          console.error(err);
          return showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Sign in failed. Please try again sending a new link.', 'error');
        }
      } else if (withRedirect) {
        result = await getRedirectResult(auth);
      }

      if (result) {
        let providerName;
        let idToken;

        // Determine the provider and get token
        if (result.providerId === "google.com") {
          providerName = "google";
          idToken = await result.user.getIdToken();
        } else if (result.providerId === "apple.com") {
          providerName = "apple";
          const tokenResult = await result.user.getIdTokenResult(true);
          idToken = tokenResult.token;
        } else if (result.providerId === "microsoft.com") {
          providerName = "microsoft";
          idToken = await result.user.getIdToken();
        } else if (result.providerId === null) { // It's the email link provider
          providerName = "email";
          idToken = await result.user.getIdToken();
        }

        // If provider is recognized, continue with sign in
        if (providerName && idToken) {
          const response = await axios.post(
            getApiRoute("auth", "POST_SIGNIN"),
            { idToken, provider: providerName },
            { withCredentials: true }
          );
          if (response.status === 200) {
            hasSignedInRef.current.status = true;
            hasSignedInRef.current.providerName = providerName;
            getAuth();
            showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, "Signing you in...", 'success');
          } else {
            showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Sign in failed. Please try again.', 'error');
          }
        } else {
          showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, 'Sign in failed. Please try again.', 'error');
        }
      }
    } catch (err) {
      // Handle errors
      console.log(err);
      let errorMessage = err?.response?.data?.message;
      showSnackBar(setSnackbarOpen, setSnackbarMessage, setSnackbarSeverity, errorMessage ?? 'Sign in failed. Please try again.', 'error');
    }
  };

  return { user, isLoading, handleSignOut, refetchAuth: getAuth, checkSignIn };
}
