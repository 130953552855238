import React, { useContext, useState, useEffect } from "react";
import { AuthContext } from "src/utils/AuthContext.js";
import {
  Typography,
  Box,
  Button,
  Icon,
  Stack,
  FormHelperText,
  FormControl,
  InputLabel,
  Divider,
  Skeleton,
  Link,
} from "@mui/material";
import Footer from "../blocks/Footer.js";
import ActionCard from "../blocks/ActionCard.js";
import { LoadingButton } from "../blocks/LoadingButton.js";
import {
  useStripe,
  useElements,
  CardElement,
  PaymentRequestButtonElement,
} from "@stripe/react-stripe-js";
import axios from "axios";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import { getApiRoute, getPageRoute } from "src/services/Config.js";
import { useTheme } from "@emotion/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import LoadingAnimation from "src/components/blocks/LoadingAnimation.js";
import FAQAccordion from "../instructions/FAQAccordian.js";
import { useSnackbarContext } from "src/utils/SnackbarContext.js";
import { customEvent } from "src/utils/gtag.js";
import { rollbar } from "src/utils/ErrorBoundary/index.js";
import { Helmet } from "react-helmet";

const PaymentCheckout = () => {
  const { user, refetchAuth } = useContext(AuthContext);
  const { type, trialPeriodDays } = useParams(); // 'consumer_annual', 'consumer_monthly', 'consumer_8_weekly', 'creator', 'creator-mailing-list'
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const {
    setSnackbarOpen,
    setSnackbarMessage,
    setSnackbarSeverity,
    showSnackBar,
  } = useSnackbarContext();

  let { successUrl, cancelUrl } = searchParams;
  successUrl =
    successUrl ||
    new URLSearchParams(window.location.search).get("successUrl") ||
    "/";
  cancelUrl =
    cancelUrl ||
    new URLSearchParams(window.location.search).get("cancelUrl") ||
    -1;
  const theme = useTheme();

  const [cardError, setCardError] = useState(null);
  const [cardComplete, setCardComplete] = useState(false);
  const [cardFocused, setCardFocused] = useState(false);

  const faqData = [
    {
      question: "How do I make the most of the free trial?",
      answer: (
        <>
          The free trial is designed to help you understand whether or not
          Stanza can help you keep up with the things you care about, to
          ultimately spend your time more meaningfully. <br /> <br /> Add as
          many calendars as you like, organize them using groups and get faster
          updates.
        </>
      ),
    },
    {
      question: "Will I be notified before my free trial ends?",
      answer:
        "Yes, you will receive an email from Stripe notifying you of what you will be billed for and when.",
    },
    {
      question: "How can I cancel my free trial?",
      answer: `After you sign up for the free trial, you'll see 'Your Plan' in the drop down menu in the top right corner. Click on 'Your Plan' and then 'Cancel Subscription' on the next screen. It's as easy as as two clicks.`,
    },
    {
      question: "My payment information isn't being accepted.",
      answer: (
        <>
          If you're experiencing issues with a valid card not being accepted,
          please send us an email at{" "}
          <Link href="mailto:founders@stanza.co">founders@stanza.co</Link> with
          more details on the error. We'll look into it asap.
        </>
      ),
    },
  ];

  const fetchPriceDetails = async () => {
    if (stripe && type) {
      try {
        const response = await axios.get(
          getApiRoute("upgrade", "STRIPE_PRICE_DETAILS", {}, { type })
        );
        return response.data;
      } catch (error) {
        setError("Failed to retrieve price details.");
      }
    }
  };

  const {
    data: priceDetails,
    isLoading: isLoadingPriceDetails,
    refetch: refetchStripePriceDetails,
  } = useQuery([`stripe-price-details`], fetchPriceDetails, {
    enabled: !!stripe,
  });

  useEffect(() => {
    if (user.hasValidSubscription) {
      showSnackBar(
        setSnackbarOpen,
        setSnackbarMessage,
        setSnackbarSeverity,
        "You already have an active Stanza+ subscription.",
        "success"
      );
      setTimeout(() => {
        navigate(getPageRoute("home", "HOME"));
      }, 2000);
    }
  }, [user]);

  useEffect(() => {
    if (user)
      customEvent({
        name: `landed_on_pay_now`,
        category: "Upgrade",
        label: "Landed on Pay Now",
        data: {
          user_id: user._id,
          user_email: user.email,
          user_handle: user.handle ?? "no_handle",
          trial_period: trialPeriodDays > 0,
        },
      });
  }, [Boolean(user)]);

  useEffect(() => {
    refetchStripePriceDetails();
  }, [stripe]);

  useEffect(() => {
    if (paymentRequest) {
      paymentRequest.on("paymentmethod", handlePaymentMethodReceived);
    }
  }, [paymentRequest]);

  const {
    mutateAsync: initializePaymentRequest,
    isLoading: isLoadingPaymentRequest,
  } = useMutation(async () => {
    if (stripe && priceDetails) {
      const { amount, label, currency } = priceDetails;

      const pr = stripe.paymentRequest({
        country: "US",
        currency: currency || "usd",
        total: {
          label: trialPeriodDays > 0 ? `${trialPeriodDays} free days` : label,
          amount: trialPeriodDays > 0 ? 0 : amount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        disableWallets: ["link"],
      });

      const result = await pr.canMakePayment();
      if (result) {
        setPaymentRequest(pr);
      } else {
        setPaymentRequest(null);
      }
    }
  });

  useEffect(() => {
    if (stripe && priceDetails) {
      initializePaymentRequest();
    }
  }, [stripe, priceDetails]);

  const trackPaymentFailure = (message, error = new Error()) => {
    customEvent({
      name: `native_payment_failure`,
      category: "Upgrade",
      label: "Failure Native Payment",
      data: {
        user_id: user._id,
        user_email: user.email,
        user_handle: user.handle ?? "no_handle",
        trial_period: trialPeriodDays > 0,
        message,
      },
    });
    rollbar.error("Failure Native Payment", error, {
      user_id: user._id,
      user_email: user.email,
      user_handle: user.handle ?? "no_handle",
      trial_period: trialPeriodDays > 0,
      message,
    });
  };

  const handlePayment = async ({
    isPaymentRequest,
    paymentMethodId,
    event,
  }) => {
    if (!isPaymentRequest) event.preventDefault();
    setLoading(true);

    try {
      if (trialPeriodDays > 0) {
        customEvent({
          name: "trial_period_payment",
          category: "Upgrade",
          label: "Requested Trial Period Payment",
          data: {
            user_id: user._id,
            user_email: user.email,
            user_handle: user.handle ?? "no_handle",
          },
        });
      }

      // Use the provided payment method ID or create one using the CardElement
      let methodId = paymentMethodId;

      if (!isPaymentRequest) {
        if (!stripe || !elements) {
          setError("Stripe has not loaded yet.");
          trackPaymentFailure("Stripe has not loaded yet.");
          setLoading(false);
          return;
        }

        if (cardError || !cardComplete) {
          setError("Please enter your card details.");
          setLoading(false);
          return;
        }

        const { error: stripeError, paymentMethod } =
          await stripe.createPaymentMethod({
            type: "card",
            card: elements.getElement(CardElement),
            billing_details: {
              email: user.email,
            },
          });

        if (stripeError) {
          setError(stripeError.message);
          trackPaymentFailure(stripeError.message, stripeError);
          setLoading(false);
          return;
        }

        methodId = paymentMethod.id;
      }

      // Proceed with the backend call
      const response = await axios.post(
        getApiRoute("upgrade", "STRIPE_NATIVE_CHECKOUT"),
        {
          type,
          paymentMethodId: methodId,
          trialPeriodDays,
        },
        { withCredentials: true }
      );

      const { clientSecret } = response.data;

      // Process the payment
      if (clientSecret) {
        const { error: confirmError } = await stripe.confirmCardPayment(
          clientSecret
        );

        if (confirmError) {
          setError(confirmError.message);
          trackPaymentFailure(confirmError.message, confirmError);
          event?.complete?.("fail"); // Handle PaymentRequest event completion
          return;
        }
      }

      // Success: Update auth and navigate
      await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 sec to leave the backend update upgrade status
      await refetchAuth();
      navigate(successUrl);
      customEvent({
        name: `native_payment_successful`,
        category: "Upgrade",
        label: "Successful Native Payment",
        data: {
          user_id: user._id,
          user_email: user.email,
          user_handle: user.handle ?? "no_handle",
          trial_period: trialPeriodDays > 0,
        },
      });
      event?.complete?.("success"); // Handle PaymentRequest event completion
    } catch (err) {
      setError(err.response?.data?.message || "An error occurred");
      trackPaymentFailure(
        err.response?.data?.message || "An error occurred",
        err
      );
      event?.complete?.("fail"); // Handle PaymentRequest event completion
    } finally {
      setLoading(false);
    }
  };

  const handlePaymentMethodReceived = async (event) => {
    await handlePayment({
      isPaymentRequest: true,
      paymentMethodId: event.paymentMethod.id,
      event,
    });
  };

  const cardElementStyles = {
    base: {
      color: "#000000",
      fontSize: "16px",
      fontFamily: ["Satoshi", "Nunito", "Open Sans", "Arial"].join(","),
      "::placeholder": {
        color: "rgba(0, 0, 0, 0.54)",
      },
    },
    invalid: {
      color: "#FF4C4C",
    },
  };

  const cardElementWrapperStyles = {
    padding: "18.5px 14px",
    borderRadius: "4px",
    border: `1px solid ${
      cardFocused ? "#000000" : cardError ? "#f44336" : "rgba(0, 0, 0, 0.23)"
    }`,
    "&:hover": {
      borderColor: cardFocused ? "#000000" : "rgba(0, 0, 0, 0.87)",
    },
    position: "relative",
  };

  return (
    <>
      <Helmet>
        <title>Stanza - Payment Checkout</title>
      </Helmet>
      {isLoadingPriceDetails ? (
        <LoadingAnimation />
      ) : !priceDetails ? (
        <ActionCard
          title="No price details"
          description="Failed to retrieve price details."
          primaryButtonCTA="Go Home"
          primaryAction={() => navigate("/")}
          iconName="highlight_off"
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            minHeight: "100vh",
          }}
        >
          <Box
            id="container"
            sx={{
              margin: "5% auto 0 auto",
              maxWidth: { xs: "100%", sm: "400px" },
              width: { xs: "-webkit-fill-available", sm: "100%" },
              position: "relative",
              p: 2,
              px: { xs: 3.5, sm: 2 },
              textAlign: "center",
            }}
          >
            <Button
              variant="text"
              onClick={() => navigate(cancelUrl)}
              sx={{ color: "text.secondary", float: "left", px: 0 }}
            >
              GO BACK
            </Button>
            <Icon
              baseClassName="material-symbols-outlined"
              sx={{
                display: "block",
                margin: "24px auto",
                color: `${theme.palette.icon.primary}`,
                verticalAlign: "middle",
                fontSize: "3rem",
                mt: 5,
                mb: 1,
                fontWeight: 300,
              }}
            >
              payment
            </Icon>
            {priceDetails && (
              <>
                <Typography variant="title" sx={{ mb: 1 }}>
                  {priceDetails.label}
                </Typography>
                {trialPeriodDays > 0 ? (
                  <Box sx={{ mt: 0.5, mb: 4 }}>
                    <Typography variant="h6">
                      {trialPeriodDays} free days
                    </Typography>
                    <Typography
                      variant="subtitle1"
                      sx={{ color: "text.secondary" }}
                    >
                      then $2.50/mo (billed annually)
                    </Typography>
                  </Box>
                ) : (
                  <Typography variant="h6" sx={{ mb: 3 }}>
                    for ${(priceDetails.amount / 100).toFixed(2)}{" "}
                    {priceDetails.recurring?.interval &&
                      `per ${priceDetails.recurring.interval}`}
                  </Typography>
                )}
              </>
            )}
            {isLoadingPaymentRequest ? (
              <Skeleton
                animation="wave"
                component="div"
                variant="rounded"
                sx={{ borderRadius: "4px", height: "40px", mb: 2.5 }}
              ></Skeleton>
            ) : paymentRequest ? (
              <>
                <PaymentRequestButtonElement
                  options={{ paymentRequest }}
                  style={{
                    paymentRequestButton: {
                      type: "default", // 'default' | 'donate' | 'buy' | 'book' | 'checkout' | 'subscribe'
                      theme: "dark", // 'dark' | 'light' | 'light-outline'
                    },
                  }}
                />
                <Divider sx={{ my: 2.5 }}>
                  <Typography variant="subtitle2">or pay with card</Typography>
                </Divider>
              </>
            ) : (
              <> </>
            )}

            <form
              onSubmit={(event) =>
                handlePayment({
                  event, // The submit event to prevent default behavior
                  isPaymentRequest: false, // Specify it's a CardElement payment
                  paymentMethodId: null, // No pre-existing payment method
                })
              }
            >
              <Stack spacing={2.5}>
                <FormControl fullWidth variant="outlined">
                  <InputLabel
                    shrink
                    error={!!cardError}
                    sx={{
                      backgroundColor: "#fff",
                      padding: "0 4px",
                      pointerEvents: "none",
                    }}
                  >
                    Card Details
                  </InputLabel>
                  <Box
                    sx={cardElementWrapperStyles}
                    onClick={() => {
                      const cardElement = elements.getElement(CardElement);
                      cardElement.focus();
                    }}
                  >
                    <CardElement
                      options={{
                        style: cardElementStyles,
                        hidePostalCode: true, // Simplify the fields
                        disableLink: true,
                      }}
                      onChange={(event) => {
                        setCardError(event.error ? event.error.message : null);
                        setCardComplete(event.complete);
                      }}
                      onFocus={() => setCardFocused(true)}
                      onBlur={() => setCardFocused(false)}
                    />
                  </Box>
                  {cardError && (
                    <FormHelperText error>{cardError}</FormHelperText>
                  )}
                </FormControl>
                {error && <Typography color="error">{error}</Typography>}
                <LoadingButton
                  variant="contained"
                  color="primary"
                  type="submit"
                  disabled={!stripe}
                  loading={loading}
                  fullWidth
                  sx={{ mt: 2 }}
                >
                  {trialPeriodDays > 0 ? "Pay $0 Today" : "Pay Now"}
                </LoadingButton>
              </Stack>
            </form>
            <Typography variant={"h2"} sx={{ mt: 6, mb: 2 }}>
              FAQs
            </Typography>
            <FAQAccordion faqData={faqData} />
            <Button
              size="grown"
              variant="outlined"
              onClick={() =>
                window.open(
                  "https://stanza-1.gitbook.io/stanza-help-center/for-subscribers/stanzaplus/benefits"
                )
              }
              sx={{ mt: 3, mb: 10, height: "auto", width: "100%" }}
            >
              See all Stanza+ Benefits
            </Button>
          </Box>
          {/* <Footer /> */}
        </div>
      )}
    </>
  );
};

export default PaymentCheckout;
