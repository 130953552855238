import { memo } from "react";
import { parseDateEvent } from "src/utils/Utils.js";
import { Typography, Box } from "@mui/material";

const EventDate = memo(({ eventDateStart, isTrendingOrAdded }) => {
  const eventDateParsed = eventDateStart
    ? parseDateEvent(eventDateStart[3]).eventParsed
    : "";
  const eventDateFormatted = eventDateParsed.toLocaleString("en-US", {
    weekday: "short",
    month: "short",
    day: "numeric",
  });
  return (
    <Box
      sx={{
        position: "relative",
        top: isTrendingOrAdded ? "2.75rem" : "0.25rem",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        textAlign: "center",
        gap: 0.2,
      }}
    >
      <Typography
        variant="subtitle1"
        color="text.primary"
        sx={{
          fontWeight: 700,
          fontSize: "10px",
          letterSpacing: "0.4px",
          lineHeight: "1",
        }}
      >
        {eventDateFormatted.split(" ")[0].toUpperCase().slice(0, -1)}
      </Typography>
      <Typography
        variant="h2"
        color="text.primary"
        id="event-number-day"
        sx={{ lineHeight: "1" }}
      >
        {eventDateFormatted.split(" ")[2]}
      </Typography>
      <Typography
        variant="caption"
        color="text.primary"
        sx={{ lineHeight: "1" }}
      >
        {eventDateFormatted.split(" ")[1].toLowerCase()}
      </Typography>
    </Box>
  );
});

export default EventDate;
