import * as React from "react";
import {
  IconButton,
  Dialog,
  DialogContent,
  Grow,
  DialogTitle,
} from "@mui/material";
import { Close } from "@mui/icons-material";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Grow ref={ref} {...props} />;
});

const CenterDialog = React.forwardRef(
  (
    {
      open,
      handleClose,
      children,
      dialogTitle,
      maxWidth = "sm",
      dividers,
      sx,
      hideCloseButton = false,
      openOnScroll = false,
      ...props
    },
    ref
  ) => {
    const CloseButton = () => {
      return (
        <IconButton
          edge="end"
          color="inherit"
          variant="outlined"
          data-testid="dialog-close-button"
          onClick={handleClose}
          aria-label="close"
          sx={{ position: "absolute", top: "12px", right: "32px" }}
        >
          <Close fontSize="small" />
        </IconButton>
      );
    };

    return (
      <Dialog
        ref={ref}
        sx={{
          ".MuiDialog-paper": { borderRadius: "16px" },
          ...sx,
        }}
        maxWidth={maxWidth}
        open={open}
        onClose={handleClose}
        TransitionComponent={openOnScroll ? undefined : Transition}
        {...props}
      >
        {dialogTitle && (
          <DialogTitle sx={{ m: 0, p: 2, ml: 1, fontSize: "16px" }}>
            {dialogTitle}
          </DialogTitle>
        )}
        {!hideCloseButton && <CloseButton />}
        <DialogContent dividers={dividers} sx={{ padding: "16px 24px 32px" }}>
          {children}
        </DialogContent>
      </Dialog>
    );
  }
);

export default CenterDialog;
