import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import ImportCalendar from "./components/ImportCalendar.js";
import Home from "./components/Home.js";
import Timeline from "./components/Timeline.js";
import CalendarSettings from "./components/CalendarSettings.js";
import SignIn from "./components/SignIn.js";
import Library from "./components/Library.js";
import Search from "./components/Search.js";
import Privacy from "./components/Privacy.js";
import Terms from "./components/Terms.js";
import {
  ProtectedRoute,
  ProtectedCreatorUpgradeRoute,
} from "./utils/ProtectedRoute.js";
import ConsumerUpgrade from "./components/upgrades/ConsumerUpgrade.js";
import CreatorUpgrade from "./components/upgrades/CreatorUpgrade.js";
import HowToImport from "./components/instructions/HowToImport.js";
import CreatorMailingListUpgrade from "./components/upgrades/CreatorMailingListUpgrade.js";
import GoogleCalendarExperience from "./components/instructions/google-calendar/GoogleCalendarExperience.js";
import CalendarExperience from "./components/instructions/other-calendars/CalendarExperience.js";
import ConnectGoogleCalendar from "./components/instructions/google-calendar/ConnectGoogleCalendar.js";
import { TimelineEmbedded } from "./components/TimelineEmbedded.js";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { getPageRoute } from "src/services";
import {
  PreventBackButton,
  BackNavigationPosition,
} from "src/utils/NavigationUtils.js";
import { NavigationManager } from "src/utils/NavigationManager.js";
import GoogleCalendarCallback from "./components/instructions/google-calendar/GoogleCalendarCallback.js";
import GoogleCalendarSteps from "./components/instructions/google-calendar/GoogleCalendarSteps.js";
import CalendarSteps from "./components/instructions/other-calendars/CalendarSteps.js";
import HowFreeTrialWorks from "./components/instructions/free-trial/HowFreeTrialWorks.js";
import FreeTrialCancel from "./components/instructions/free-trial/FreeTrialCancel.js";
import GoogleCalendarConnected from "./components/instructions/google-calendar/GoogleCalendarConnected.js";
import CalendarConnected from "./components/instructions/other-calendars/CalendarConnected.js";
import { TopNavbarProvider } from "./utils/TopBannerContext.js";
import PaymentCheckout from "./components/upgrades/NativePaymentCheckout.js";
import PaymentConfirmation from "./components/upgrades/PaymentConfirmation.js";
import ChooseFreePlan from "./components/instructions/free-plan/ChooseFreePlan.js";

function App() {
  /*
    Refetch on window focus or reconnect
    keep previous data until cacheTime.
  */
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: 1000 * 60 * 10, // 10 minutes
        keepPreviousData: true,
        refetchOnWindowFocus: true,
        refetchOnReconnect: true,
        onError: (error) => {
          console.error("Query error:", error);
        },
      },
    },
  });

  return (
    <QueryClientProvider client={queryClient}>
      <Router>
        <TopNavbarProvider>
          <PreventBackButton />
          <NavigationManager />
          <BackNavigationPosition />
          <Routes>
            <Route path={getPageRoute("home", "HOME")} element={<Home />} />
            <Route
              path={getPageRoute("home", "TAG")}
              element={<Home key={window.location.pathname} />}
            />
            <Route path={getPageRoute("chat", "SEARCH")} element={<Search />} />
            <Route
              path={getPageRoute("chat", "SEARCH_TAB")}
              element={<Search />}
            />
            <Route
              path={getPageRoute("calendar", "LEGACY_TIMELINE")}
              element={<Timeline />}
            />
            <Route
              path={getPageRoute("calendar", "LEGACY_SUBSCRIPTION")}
              element={<Timeline />}
            />
            <Route
              path={getPageRoute("calendar", "LEGACY_SCHEDULES")}
              element={<Timeline />}
            />
            <Route
              path={getPageRoute("calendar", "ALL_EVENTS")}
              element={<Timeline />}
            />
            <Route
              path={getPageRoute("calendar", "CALENDAR_EVENTS")}
              element={<Timeline />}
            />
            <Route
              path={getPageRoute("calendar", "GROUP_EVENTS")}
              element={<Timeline />}
            />

            {/* --- 🕴️ CREATOR ROUTES --- */}
            <Route
              path={getPageRoute("calendar", "PREVIEW_CALENDAR")}
              element={<Timeline preview={true} />}
            />
            <Route
              path={getPageRoute("calendar", "PREVIEW_CALENDAR_EVENTS")}
              element={<Timeline preview={true} />}
            />
            <Route
              path={getPageRoute("calendar", "CALENDAR_SETTINGS")}
              element={<ProtectedRoute Component={CalendarSettings} />}
            />
            <Route
              path={getPageRoute("help", "HELP_IMPORT")}
              element={<ProtectedRoute Component={HowToImport} />}
            />
            <Route
              path={getPageRoute("calendar", "IMPORT")}
              element={
                <ProtectedRoute
                  Component={() => (
                    <ProtectedCreatorUpgradeRoute Component={ImportCalendar} />
                  )}
                />
              }
            />
            <Route path={getPageRoute("auth", "SIGNIN")} element={<SignIn />} />
            <Route
              path={getPageRoute("library", "LIBRARY")}
              element={<ProtectedRoute Component={Library} />}
            />

            {/* --- 📅 OTHER CALENDAR ROUTES --- */}
            <Route
              path={getPageRoute("help", "SUBSCRIBING_CALENDAR")}
              element={<ProtectedRoute Component={CalendarExperience} />}
            />

            <Route
              path={getPageRoute("help", "SUBSCRIBING_CALENDAR_STEPS")}
              element={<ProtectedRoute Component={CalendarSteps} />}
            />

            <Route
              path={getPageRoute("help", "CALENDAR_CONNECTED")}
              element={<ProtectedRoute Component={CalendarConnected} />}
            />

            {/* --- 📆 GOOGLE CALENDAR ROUTES --- */}
            <Route
              path={getPageRoute("help", "SUBSCRIBING_GOOGLE_CALENDAR")}
              element={<ProtectedRoute Component={GoogleCalendarExperience} />}
            />
            <Route
              path={getPageRoute("help", "SUBSCRIBING_GOOGLE_CALENDAR_STEPS")}
              element={<ProtectedRoute Component={GoogleCalendarSteps} />}
            />
            <Route
              path={getPageRoute("help", "CONNECT_GOOGLE_CALENDAR")}
              element={<ProtectedRoute Component={ConnectGoogleCalendar} />}
            />
            <Route
              path={getPageRoute("help", "GOOGLE_CALENDAR_CONNECTED")}
              element={<ProtectedRoute Component={GoogleCalendarConnected} />}
            />
            <Route
              path={getPageRoute("auth", "GOOGLE_CALENDAR_CALLBACK")}
              element={<ProtectedRoute Component={GoogleCalendarCallback} />}
            />

            {/* --- 🚀 UPGRADE ROUTES --- */}
            <Route
              path={getPageRoute("upgrade", "UPGRADE_PLUS")}
              element={<ProtectedRoute Component={ConsumerUpgrade} />}
            />
            <Route
              path={getPageRoute("upgrade", "UPGRADE_CREATOR")}
              element={<ProtectedRoute Component={CreatorUpgrade} />}
            />
            <Route
              path={getPageRoute("upgrade", "UPGRADE_CREATOR_MAILING_LIST")}
              element={<ProtectedRoute Component={CreatorMailingListUpgrade} />}
            />
            <Route
              path={getPageRoute("upgrade", "PAYMENT_CHECKOUT")}
              element={<ProtectedRoute Component={PaymentCheckout} />}
            />
            <Route
              path={getPageRoute("upgrade", "PAYMENT_STANZA_PLUS_CONFIRMATION")}
              element={<ProtectedRoute Component={PaymentConfirmation} />}
            />
            <Route
              path={getPageRoute("help", "FREE_TRIAL_CANCEL")}
              element={<ProtectedRoute Component={FreeTrialCancel} />}
            />
            <Route
              path={getPageRoute("help", "FREE_TRIAL_STEPS")}
              element={<ProtectedRoute Component={HowFreeTrialWorks} />}
            />

            {/* --- 🆓 FREE PLAN ROUTES --- */}
            <Route
              path={getPageRoute("help", "CHOOSE_FREE_PLAN")}
              element={<ProtectedRoute Component={ChooseFreePlan} />}
            />

            {/* --- 📄 TERMS & PRIVACY ROUTES --- */}
            <Route
              path={getPageRoute("privacy", "PRIVACY")}
              element={<Privacy />}
            />
            <Route path={getPageRoute("terms", "TERMS")} element={<Terms />} />
            <Route
              path={getPageRoute("embedded", "EMBEDDED_TIMELINE_EVENTS")}
              element={<TimelineEmbedded />}
            />
            {/* Test route for embedded timeline (debug webcomponent locally) */}
            {process.env.NODE_ENV !== "production" && (
              <Route
                path="/timeline_embedded_test"
                element={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `
                <div style="width: 90%; height: 231px; border: 1px solid #D9D9D9; border-radius: 8px;">
                  <stanza-timeline handle="nba-warriors" calendarId="53ae0d467f33ed8a06bd0421" direction="horizontal" />
                </div>
                <div style="width: 300px; height: 700px; border: 1px solid #D9D9D9; border-radius: 8px; margin-top: 2rem;">
                  <stanza-timeline handle="nba-warriors" calendarId="53ae0d467f33ed8a06bd0421" direction="vertical" />
                </div>
                `,
                      }}
                    />
                  </>
                }
              />
            )}
            {/* Test route for embedded timeline (debug iframe locally) */}
            {process.env.NODE_ENV !== "production" && (
              <Route
                path="/timeline_embedded_iframe_test"
                element={
                  <>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: `
                <!-- cross-iframe of embedded timeline page -->      
                <iframe style="width: 100%; height: 250px; border: 1px solid #D9D9D9; border-radius: 8px;" src="https://127.0.0.1:3000/embedded/nba-warriors/53ae0d467f33ed8a06bd0421?direction=horizontal"></iframe>
                <!-- cross-amp-iframe of stanza-timeline -->    
                <amp-iframe width="100%" height="800px" layout="fixed" frameborder="0" src="https://127.0.0.1:3000/timeline_embedded_test" i-amphtml-layout="fixed" style="width: 100%; height: 800px; display: block; margin-top: 2rem;"><i-amphtml-scroll-container class="amp-active"><iframe style="height: 100%; width: 100%;" name="amp_iframe0" frameborder="0" allow="" sandbox="allow-scripts allow-same-origin" src="https://127.0.0.1:3000/timeline_embedded_test"></iframe></i-amphtml-scroll-container></amp-iframe>
                `,
                      }}
                    />
                  </>
                }
              />
            )}
          </Routes>
        </TopNavbarProvider>
      </Router>
      {/*     <ReactQueryDevtools initialIsOpen={false} />*/}
    </QueryClientProvider>
  );
}

export default App;
