import { Box } from "@mui/material";
import BasketballIcon from "src/assets/svg/basket-ball.js";

const DefaultImageBasketball = ({ eventUniqueNumber, loading, ...props }) => {
  // this is to assign the same gradient to the same event every time
  const gradientAsignation = eventUniqueNumber
    ? parseInt(eventUniqueNumber) % 8
    : 0;
  const gradients = [
    "linear-gradient(105.36deg, #E6E7EF 0%, #DBDEF3 25.52%, #BDC6F9 53.13%, #B6BEEB 79.69%, #BDC1D8 100%)",
    "linear-gradient(105.36deg, #DFDEE6 0%, #DAD9E3 25.52%, #D7DBEA 53.13%, #CDD0E1 79.69%, #BABBCD 100%)",
    "linear-gradient(105.36deg, #E0DFE8 0%, #E8DEE8 25.52%, #F9DFE5 53.13%, #EED5DB 79.69%, #C9C4D3 100%)",
    "linear-gradient(105.36deg, #FBF1EA 0%, #FDE5D5 25.52%, #FFC8AB 53.13%, #F6CEBC 79.69%, #F0D5CA 100%)",
    "linear-gradient(105.36deg, #F3ECE5 0%, #F6E9DE 25.52%, #FCE5CF 53.13%, #F5DAB9 79.69%, #E5D0B5 100%)",
    "linear-gradient(105.36deg, #E7E8F0 0%, #E8E2F2 25.52%, #F1DAF5 53.13%, #DECDE8 79.69%, #C7C3D7 100%)",
    "linear-gradient(105.36deg, #E6E7EF 0%, #E0E9EB 25.52%, #D3F1DD 53.13%, #C6DDD3 79.69%, #C2C8D2 100%)",
    "linear-gradient(105.36deg, #F1F1F1 0%, #F5F5F6 25.52%, #DDDDDD 53.13%, #F6F6F6 79.69%, #EBEBEC 100%)",
  ];
  return (
    <Box
      display={loading ? "none" : "flex"}
      alignItems="center"
      justifyContent="center"
      sx={{ background: gradients[gradientAsignation], borderRadius: "4px" }}
      {...props}
    >
      <BasketballIcon height="28%" width="28%" style={{ opacity: 0.1 }} />
    </Box>
  );
};

export default DefaultImageBasketball;
