const BASE_API_URL = `${process.env.REACT_APP_API_URL}`;
const router = "";

export const HomeRoutes = {
  apiRoutes: Object.freeze({
    GET_TRENDING_CALENDARS: `${router}/calendar/trending`, // Get all calendars of the DB
    GET_CALENDARS_SECTIONS: `${router}/discovery/trendingsections`, // Get all sections of the home page
    GET_CALENDARS_BY_TAGS: `${router}/discovery/calendarsbytags`, // Search calendars by tags
    GET_LEADERBOARD_BY_TAGS: `${router}/discovery/leaderboardbytags`, // Search leaderboards by tags
  }),
  pageRoutes: Object.freeze({
    HOME: `/`,
    TAG: "/tag/:tag",
  }),
};

export const AuthRoutes = {
  apiRoutes: Object.freeze({
    GET_AUTH: `${router}/auth`, // Get user's auth status
    POST_SIGNOUT: `${router}/auth/signout`, // Sign out user
    POST_SIGNIN: `${router}/auth/signin`, // Sign in user
    UPDATE: `${router}/auth/update`, // Update user's profile
    SKIP_OR_SUBMIT_MAILINGLIST: `${router}/auth/mailingList`, // Skip or add a user to the mailing list
    CHECK_SHOW_MAILING_LIST_DIALOG: `${router}/auth/checkshowmailinglist`, // Check if a user should be shown the mailing list dialog
    CONNECT_GOOGLE_CALENDAR: `${router}/auth/googlecalendar`, // Connect a user's Google Calendar
  }),
  pageRoutes: Object.freeze({
    SIGNIN: `/signin`, // Sign in user
    GOOGLE_CALENDAR_CALLBACK: `/auth/googlecalendar`, // Callback for Google Calendar
  }),
};

export const CalendarRoutes = {
  apiRoutes: Object.freeze({
    CREATE_CALENDAR: `${router}/calendar/create`, // Import a new calendar
    UPDATE_CALENDAR: `${router}/calendar/:handle/:calendarId`, // Update calendar info
    DELETE_CALENDAR: `${router}/calendar/delete/:calendarId`, // Delete a calendar
    GET_CALENDARS: `${router}/calendar/:handle`, // Get all calendars associated with a handle
    GET_CALENDAR: `${router}/calendar/:handle/:calendarId`, // Get calendar info
    GET_CALENDAR_EVENTS: `${router}/calendar/contents/:link`, // get the webcal contents (events) associated with calendar (link is the webcal link)
    GET_PROFILE_CALENDAR_EVENTS: `${router}/calendar/profile/contents/:link`, // Same as above but without cache
  }),
  pageRoutes: Object.freeze({
    ALL_EVENTS: `/:handle`, // View upcoming and past events for any calendar on Stanza
    CALENDAR_EVENTS: `/:handle/:calendarId`, // View upcoming and past events for a specific calendar
    CALENDAR_SETTINGS: `/:handle/:calendarId/settings`, // View settings for a specific calendar or group
    IMPORT: `/import`, // Import a new calendar
    LEGACY_TIMELINE: `/timeline/:handle`, // Legacy timeline pages
    LEGACY_SUBSCRIPTION: `/timeline/:handle/subscription`, // Legacy subscription pages
    LEGACY_SCHEDULES: `/schedules/:handle`, // Legacy schedules pages
    PREVIEW_CALENDAR: `/preview/:handle`, // Preview a creator's calendar
    PREVIEW_CALENDAR_EVENTS: `/preview/:handle/:calendarId`, // Preview fan's view of a creator's calendar
  }),
};

export const UserRoutes = {
  apiRoutes: Object.freeze({
    ADD_CALENDAR: `${router}/user/add`, // Add a calendar to a user's list of added calendars
    REMOVE_CALENDAR: `${router}/user/remove`, // Remove a calendar from a user's list of added calendars
    CREATE_GROUP: `${router}/user/group/create`, // Create a new group
    UPDATE_GROUP: `${router}/user/group/update`, // Update a group's metadata that a user has created
    DELETE_GROUP: `${router}/user/group/delete`, // Delete a group that a user has created
    ADD_CALENDARS_TO_GROUP: `${router}/user/group/addcalendars`, // Add calendars to a group
    REMOVE_CALENDARS_FROM_GROUP: `${router}/user/group/removecalendars`, // Remove calendars from a group
    ADD_GROUP: `${router}/user/group/add`, // Add a group to a user's list of added groups
    REMOVE_GROUP: `${router}/user/group/remove`, // Remove a group from a user's list of added groups
    GET_USER_CALENDARS: `${router}/user/calendars`, // Get user's added & created calendars
    GET_USER_EVENTS: `${router}/user/events`, // Get user's upcoming events
    START_FREE_TRIAL: `${router}/user/startfreetrial`, // Start a free trial for a user
    CHOOSE_FREE_PLAN: `${router}/user/choosefreeplan`, // Choose the free plan for a user
    ADD_TAGS: `${router}/user/tags/add`, // Add tags to a user's profile
    REMOVE_TAGS: `${router}/user/tags/remove`, // Remove tags from a user's profile
    UPDATE_TAGS: `${router}/user/tags/update`, // Update a user's tags
  }),
};

export const ImageRoutes = {
  apiRoutes: Object.freeze({
    UPDATE_PROFILE_IMAGE: `${router}/image/updateprofile`, // Update user's profile image
    UPDATE_IMAGE_URL: `${router}/image/:calendarId/:eventId`, // Update the image url for a specific event
  }),
};

export const LibraryRoutes = {
  apiRoutes: Object.freeze({
    GET_GOOGLE_CALENDAR_STATUS: `${router}/googlecalendar/status`,
    FORCE_REFRESH_GOOGLE_CALENDAR: `${router}/googlecalendar/refresh/:type/:calendarId/:handle`,
  }),
  pageRoutes: Object.freeze({
    LIBRARY: `/library`, // View all added or imported calendars, and created groups by the user
  }),
};

export const UpgradeRoutes = {
  apiRoutes: Object.freeze({
    STRIPE_CHECKOUT: `${router}/stripe/checkout`, // Create a new Stripe checkout session
    STRIPE_NATIVE_CHECKOUT: `${router}/stripe/nativecheckout`, // Create a new Stripe native checkout session
    STRIPE_CUSTOMER_PORTAL: `${router}/stripe/customerportal`, // Create a new Stripe customer portal session
    STRIPE_PRICE_DETAILS: `${router}/stripe/pricedetails`,
  }),
  pageRoutes: Object.freeze({
    UPGRADE_PLUS: `/upgrade/plus`,
    UPGRADE_CREATOR: `/upgrade/creator`,
    UPGRADE_CREATOR_MAILING_LIST: `/upgrade/creator-mailing-list`,
    PAYMENT_CHECKOUT: `/upgrade/:type/:trialPeriodDays/payment`,
    PAYMENT_STANZA_PLUS_CONFIRMATION: "/upgrade/confirmation",
  }),
};

export const HelpRoutes = {
  pageRoutes: Object.freeze({
    HELP_IMPORT: `/help/import`,
    SUBSCRIBING_GOOGLE_CALENDAR: `/subscribing/google-calendar`,
    SUBSCRIBING_CALENDAR: `/subscribing/calendar`,
    SUBSCRIBING_GOOGLE_CALENDAR_STEPS: `/subscribing/google-calendar/steps`,
    SUBSCRIBING_CALENDAR_STEPS: `/subscribing/calendar/steps`,
    CONNECT_GOOGLE_CALENDAR: `/connect/google-calendar`,
    GOOGLE_CALENDAR_CONNECTED: "/subscribing/google-calendar/connected",
    CALENDAR_CONNECTED: "/subscribing/calendar/connected",
    FREE_TRIAL_CANCEL: `/free-trial/cancel`,
    FREE_TRIAL_STEPS: `/free-trial/steps`,
    CHOOSE_FREE_PLAN: `/choose-free-plan`,
  }),
};

export const ChatRoutes = {
  apiRoutes: Object.freeze({
    SEARCH: `${router}/chat/search`, // Search for calendars and groups
    DISCOVER: `${router}/chat/discover`, // Search for calendars and groups
    SUPPORT: `${router}/chat/support`, // Search for calendars and groups
  }),
  pageRoutes: Object.freeze({
    SEARCH: `/search`, // Search for calendars and groups
    SEARCH_TAB: `/search/:activeTab`, // Search for calendars and groups
  }),
};

export const EmbeddedRoutes = {
  pageRoutes: Object.freeze({
    EMBEDDED_TIMELINE_EVENTS: `/embedded/:handle/:calendarId`, // Timeline Events of specific calendar for embedding
  }),
};

export const PrivacyRoutes = {
  pageRoutes: Object.freeze({
    PRIVACY: `/privacy`, // Privacy policy
  }),
};

export const TermsRoutes = {
  pageRoutes: Object.freeze({
    TERMS: `/terms`, // Terms of service
  }),
};

export const EventRoutes = {
  apiRoutes: Object.freeze({
    CREATE_EVENT: `${router}/event/create`,
    UPDATE_EVENT: `${router}/event/update/:calendarId/:eventId`,
    DELETE_EVENT: `${router}/event/delete/:calendarId/:eventId`,
  }),
};

const routesEndpoints = {
  home: HomeRoutes,
  auth: AuthRoutes,
  calendar: CalendarRoutes,
  image: ImageRoutes,
  library: LibraryRoutes,
  upgrade: UpgradeRoutes,
  help: HelpRoutes,
  chat: ChatRoutes,
  embedded: EmbeddedRoutes,
  privacy: PrivacyRoutes,
  terms: TermsRoutes,
  event: EventRoutes,
  user: UserRoutes,
};

// Generate a url with params object
export const generateParamUrl = (endpoint, params = {}) => {
  const url = Object.keys(params).reduce((prev, current) => {
    return prev.replace(new RegExp(`:${current}[?]?`, "g"), params[current]);
  }, endpoint);
  return url || endpoint;
};

// Get a page route
export const getPageRoute = (module, endpoint, params, queryParams = {}) => {
  const endpointUrl = routesEndpoints?.[module]?.pageRoutes?.[endpoint];
  let stringfiedParams = new URLSearchParams(queryParams).toString();
  if (stringfiedParams) stringfiedParams = `?${stringfiedParams}`;
  return `${generateParamUrl(endpointUrl, params)}${stringfiedParams}`;
};

// Get an api route
export const getApiRoute = (module, endpoint, params, queryParams = {}) => {
  const endpointUrl = routesEndpoints?.[module]?.apiRoutes?.[endpoint];
  let stringfiedParams = new URLSearchParams(queryParams).toString();
  if (stringfiedParams) stringfiedParams = `?${stringfiedParams}`;
  return `${BASE_API_URL}${generateParamUrl(
    endpointUrl,
    params
  )}${stringfiedParams}`;
};
