import StepsScreen from "src/components/instructions/StepsScreen.js";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { AuthContext } from "src/utils/AuthContext.js";
import { LoadingButton } from "../../blocks/LoadingButton.js";
import { Typography } from "@mui/material";
import { getPageRoute } from "src/services";
import { UpgradeConsumerButton } from "../../upgrades/UpgradeConsumerButton.js";

const CalendarSteps = () => {
  const steps = [
    {
      title: "Unlock transparent updates",
      description:
        "See a timestamp in every calendar event letting you know when your calendar last updated.",
      icon: "visibility",
    },
    {
      title: "Take control of your updates",
      description:
        "Out of date? Don't wait till your calendar updates next, resubscribe to make sure you have the latest.",
      icon: "refresh",
    },
    {
      title: "Know what changed",
      description:
        "Coming soon: See which events were updated, and what changes were made within your calendar.",
      icon: "change_history",
    },
  ];

  const navigate = useNavigate();
  const { user } = useContext(AuthContext);

  return (
    <StepsScreen title="How it works" steps={steps}>
      <Typography variant={"h2"} sx={{ textAlign: "left", mr: "auto" }}>
        This is a new Stanza+ feature
      </Typography>

      {user && !user.hasValidSubscription ? (
        <>
          <Typography
            variant={"subtitle2"}
            sx={{ textAlign: "left", mr: "auto", mt: 1 }}
            color="text.secondary"
          >
            Save time and unlock unlimited calendar subscriptions.
          </Typography>
          <UpgradeConsumerButton
            size="grown"
            variant="contained"
            color="primary"
            sx={{ mt: 2, height: "68px", width: "100%" }}
            successUrl={getPageRoute("help", "CALENDAR_CONNECTED")}
          >
            <div>
              TRY FREE FOR 1 WEEK
              <br />
              <span style={{ textTransform: "capitalize" }}>Pay $0 today</span>
            </div>
          </UpgradeConsumerButton>
          <Typography
            variant="subtitle2"
            sx={{
              pt: 1.5,
              margin: "auto",
              color: "text.secondary",
              mb: 4,
              width: "90%",
              flex: 1,
            }}
          >
            Cancel any time before trial ends. After trial, Stanza+ is $2.50/mo
            (billed annually).
          </Typography>
        </>
      ) : (
        <>
          <Typography
            variant={"subtitle2"}
            sx={{ textAlign: "left", mr: "auto", mt: 1 }}
            color="text.secondary"
          >
            Members save time and are the first to know.
          </Typography>
          <LoadingButton
            size="grown"
            variant="contained"
            color="primary"
            sx={{ mt: 2, height: "auto", width: "100%" }}
            onClick={() =>
              navigate(getPageRoute("help", "CALENDAR_CONNECTED"))
            }
          >
            Get Started
          </LoadingButton>
        </>
      )}
    </StepsScreen>
  );
};

export default CalendarSteps;
