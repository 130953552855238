import { getPageRoute } from "src/services";
import LoadingAnimation from "./blocks/LoadingAnimation.js";
import Event from "./blocks/Event.js";
import { useScrollButtons } from "src/utils/useScrollButtons.js";
import { TimelineEmbeddedStyles } from "src/styles/TimelineEmbeddedStyles.js";
import { useTimelineContent } from "src/utils/useTimelineContent.js";
import { Button, Typography } from "@mui/material";
import { ExpandMoreOutlined, ExpandLessOutlined } from "@mui/icons-material";
import { ProfileCard } from "./blocks/profile/ProfileUserEmbedded.js";
import React, {
  useState,
  useLayoutEffect,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import UAParser from "ua-parser-js";
import reactToWebComponent from "react-to-webcomponent";
import ReactDOM from "react-dom/client";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ThemeProvider } from "@mui/material";
import { Theme } from "src/styles/Theme.js";
import { SnackbarProvider, Snackbar } from "src/utils/SnackbarContext.js";
import { AuthProvider } from "src/utils/AuthContext.js";
import { BrowserRouter as Router, useParams } from "react-router-dom";
import ActionCard from "./blocks/ActionCard.js";
import {
  ErrorBoundary,
  ErrorComponent,
} from "src/utils/ErrorBoundary/index.js";
import Ad, { handleVenatusNewSession } from "./blocks/Ad.js";
import { Helmet } from "react-helmet";

export function getWindowProperty(propPath) {
  try {
    return propPath.split(".").reduce((obj, key) => obj?.[key], window.top);
  } catch (error) {
    return propPath.split(".").reduce((obj, key) => obj?.[key], window);
  }
}

const appendScript = (scriptElement) => {
  try {
    window.top.document.head.appendChild(scriptElement);
  } catch (error) {
    window.document.head.appendChild(scriptElement);
  }
};

const loadScript = async (scriptContent) => {
  try {
    const scriptElement = document.createElement("script");
    scriptElement.type = "text/javascript";
    scriptElement.text = scriptContent;

    appendScript(scriptElement);
  } catch (error) {
    console.error("Error loading script in window.top:", error);
  }
};

export function TimelineEmbedded({
  handle: handleProp,
  calendarId: calendarIdProp,
  direction: directionProp,
  loadAds: loadAdsProp,
  source: sourceParam, // Ex: "https://www.realgm.com"
  isWebComponent,
}) {
  const { handle: handleParam, calendarId: calendarIdParam } = useParams(); // Get the handle and calendarID from the URL

  const handle = handleProp || handleParam;
  const calendarId = calendarIdProp || calendarIdParam;
  const direction =
    directionProp ||
    new URLSearchParams(window.location.search).get("direction") ||
    "vertical";

  const {
    calendarCreator,
    upcomingEvents,
    pastEvents,
    isLoadingEvents,
    creatorView,
    selectedCalendar,
    isLoadingCalendarEvents,
    calendarNotFound,
    invalidCreator,
  } = useTimelineContent({ handle, calendarId, isEmbedded: true });

  const containerRef = React.useRef(null);
  const upButtonRef = React.useRef(null);
  const downButtonRef = React.useRef(null);

  useScrollButtons({
    containerRef: containerRef,
    upButtonRef: upButtonRef,
    downButtonRef: downButtonRef,
    scrollDelta: 200,
    direction,
  });

  const setFixedHeightDescription = () => {
    let maxHeightDescription;
    document
      .querySelectorAll("#calendar-event-title ~ div")
      .forEach((description) => {
        if (
          !maxHeightDescription ||
          description.clientHeight > maxHeightDescription
        ) {
          maxHeightDescription = description.clientHeight;
        }
      });
    document
      .querySelectorAll("#calendar-event-title ~ div")
      .forEach((description) => {
        description.style.height = `${maxHeightDescription}px`;
      });
  };

  useLayoutEffect(() => {
    if (direction === "horizontal") {
      setFixedHeightDescription();
    }
  }, [upcomingEvents?.length, pastEvents?.length, window.innerHeight]);

  const calendarUrl = useMemo(() => {
    let url;
    const path = getPageRoute("calendar", "CALENDAR_EVENTS", {
      handle: handle,
      calendarId: selectedCalendar._id,
    });
    if (isWebComponent) {
      url = `${process.env.REACT_APP_CLIENT_URL}${path}`;
    } else {
      url = path; // iframe use it as relative path
    }
    return url;
  }, [selectedCalendar, handle, isWebComponent]);

  const sxScrollButton = {
    minWidth: 0,
    width: 50,
    height: 40,
    p: 0,
    position: "relative",
    "& .MuiButton-endIcon": {
      margin: "auto",
    },
  };

  const SeeMoreSection = () => {
    return (
      <ActionCard
        id="see-more-section"
        data-testid="see-more-section"
        title="View All"
        description="See all upcoming and past events on Stanza."
        primaryButtonCTA="Go to Stanza"
        primaryAction={calendarUrl}
        iconName="calendar_view_day"
        sx={{
          borderBottom:
            pastEvents?.length > 0 ? "0.5px solid #D9D9D9 !important" : "none",
          position: "static",
          p: "28px",
          transform: "none !important",
          minWidth: "auto",
        }}
      />
    );
  };

  const CalendarNotFound = () => {
    return (
      <ActionCard
        id="calendar-not-found"
        data-testid="calendar-not-found"
        title="Calendar not found"
        description="The calendar you are looking for does not exist."
        primaryButtonCTA="Go to Stanza"
        primaryAction={() => window.open(getPageRoute("home", "HOME"))}
        iconName="calendar_month"
        sx={{
          position: "static",
          p: "0px",
          transform: "translateY(25%) !important",
          minWidth: "auto",
          minHeight: "100%",
        }}
      />
    );
  };

  const NoUpcomingEvents = () => (
    <ActionCard
      id="alert-no-upcoming"
      data-testid="alert-no-upcoming"
      title="No Upcoming Events"
      description="Add to Calendar and you'll be the first to know when new events are announced."
      iconName="calendar_view_day"
      sx={{
        borderBottom:
          pastEvents?.length > 0 ? "0.5px solid #D9D9D9 !important" : "none",
        position: "static",
        p: "28px",
        transform: "none !important",
        minWidth: "auto",
      }}
    />
  );

  const NavigateOnClick = ({ children, id }) => {
    return (
      <Button
        id={id}
        component="div"
        sx={{
          textTransform: "none",
          fontSize: "inherit",
          padding: 0,
          display: "block",
          width: "100%",
        }}
      >
        <a
          href={calendarUrl}
          target="_blank"
          rel="noreferrer"
          style={{
            textDecoration: "auto",
            WebkitTextDecorationLine: "none",
            height: "100%",
            display: "block",
          }}
        >
          {children}
        </a>
      </Button>
    );
  };

  const limitRenderedAds = 8;

  /* Enable ads for the following ads servers: (in this case customer should send "source" param to identify their own domain).
  ex: <stanza-timeline source="https://www.realgm.com"></stanza-timeline>
  */
  const enabledAdsServers = [
    "safeframe.googlesyndication.com",
    "storage.googleapis.com",
  ];

  const isTestSource =
    getWindowProperty("location.href") ===
    "https://storage.googleapis.com/fernando-testing/timeline_embedded_test.html";

  const getUrl = () => {
    const origin = getWindowProperty("location.origin");
    console.log("origin: " + origin);
    if (
      sourceParam &&
      origin.match(new RegExp(`${enabledAdsServers.join("|")}`))
    ) {
      console.log("Injecting ads for source: ", sourceParam);
      return sourceParam;
    }
    return origin;
  };

  const url = getUrl();
  const baseTarget = new URL(url).hostname.replace("www.", ""); // with subdomain
  const scriptTarget =
    baseTarget.includes("127.0.0.1") || isTestSource
      ? "49erswebzone.com"
      : baseTarget.split(".").slice(-2).join(".");

  const isScriptAlreadyAdded = useCallback((src) => {
    let scripts;
    try {
      scripts = window.top.document.head.getElementsByTagName("script");
    } catch (error) {
      scripts = document.head.getElementsByTagName("script");
    }
    for (var i = 0; i < scripts.length; i++) {
      if (scripts[i].src === src) {
        return true;
      }
    }
    return false;
  }, []);

  useEffect(() => {
    const scriptSrc = `https://hb.vntsm.com/v4/live/vms/sites/${scriptTarget}/index.js`;
    if (!loadAdsProp) return;
    if (isScriptAlreadyAdded(scriptSrc)) return;

    const fetchScript = async () => {
      try {
        const scriptElement = document.createElement("script");
        scriptElement.src = scriptSrc;
        scriptElement.type = "text/javascript";
        scriptElement.setAttribute("data-mode", "scan");
        scriptElement.async = true;

        appendScript(scriptElement);
      } catch (error) {
        console.error("Error fetching and injecting script:", error);
      }
    };

    fetchScript().then(() =>
      loadScript(`
        self.__VM = self.__VM || [];
        self.__VM.push(function (admanager, scope) {
          scope.Config.get('mpu').display('slot-mpu');
        });
      `).then(() => handleVenatusNewSession())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loadAdsProp]);

  useEffect(() => {
    if (!isWebComponent) return;
    const link1 = document.createElement("link");
    link1.href =
      "https://fonts.googleapis.com/css?family=Nunito:wght@300;400;600;700;800&display=swap";
    link1.rel = "stylesheet";
    document.head.appendChild(link1);

    const link2 = document.createElement("link");
    link2.href =
      "https://fonts.googleapis.com/css?family=Material+Symbols+Outlined:100,200,300,400,500,600,700,800,900&display=swap";
    link2.rel = "stylesheet";
    document.head.appendChild(link2);

    return () => {
      document.head.removeChild(link1);
      document.head.removeChild(link2);
    };
  }, []);

  const { browser } = UAParser(navigator.userAgent);
  const isSafari = browser.name === "Safari";

  return (
    <div
      ref={containerRef}
      className={`events-embedded-container ${
        direction === "horizontal" ? "h-mode" : "v-mode"
      } ${isSafari ? "is-safari" : ""}`}
      data-source={isWebComponent ? "webcomponent" : "iframe"}
    >
      {!isWebComponent && (
        <Helmet>
          <title>Stanza - Embedded Timeline</title>
        </Helmet>
      )}
      <TimelineEmbeddedStyles />
      <Button
        id="scroll-up-button"
        ref={upButtonRef}
        className="scroll-button scroll-button-top root-color"
        variant="outlined"
        shape="circle"
        size="large"
        sx={{
          ...sxScrollButton,
          background: "linear-gradient(0deg, white, #ffffffbf, transparent)",
          borderRadius: "0 0 50% 50%",
        }}
        endIcon={<ExpandLessOutlined />}
      />
      <Button
        id="scroll-down-button"
        ref={downButtonRef}
        className="scroll-button scroll-button-bottom root-color"
        variant="outlined"
        shape="circle"
        size="large"
        sx={{
          ...sxScrollButton,
          background: "linear-gradient(180deg, white, #ffffffbf, transparent)",
          borderRadius: "50% 50% 0 0",
        }}
        endIcon={<ExpandMoreOutlined />}
      />
      {(isLoadingCalendarEvents || isLoadingEvents) &&
      !calendarNotFound &&
      !invalidCreator ? (
        <LoadingAnimation />
      ) : (
        <>
          {calendarNotFound || invalidCreator ? (
            <CalendarNotFound />
          ) : (
            <>
              <ProfileCard
                creator={calendarCreator}
                calendarName={selectedCalendar?.name}
                handleAddToCalendar={calendarUrl}
              />
              {loadAdsProp && (
                <Ad placementName="mpu" style={{ margin: "auto " }} />
              )}
              {upcomingEvents?.length > 0 ? (
                upcomingEvents.slice(0, 25).map((upcomingEvent, index) => (
                  <div
                    style={{ display: "contents" }}
                    key={selectedCalendar?._id + "_" + direction}
                  >
                    <NavigateOnClick id="event-navigation-container">
                      <Event
                        calendar={selectedCalendar}
                        event={upcomingEvent}
                        calendarId={selectedCalendar?._id}
                        showUpload={creatorView}
                        position={index}
                        isEmbedded={true}
                      />
                    </NavigateOnClick>
                    {loadAdsProp && index < limitRenderedAds && (
                      <Ad placementName="mpu" style={{ margin: "auto" }} />
                    )}
                  </div>
                ))
              ) : (
                <NoUpcomingEvents />
              )}
              {upcomingEvents?.length > 0 && <SeeMoreSection />}
              {pastEvents?.length > 0 ? (
                <>
                  {direction !== "horizontal" && (
                    <Typography
                      variant="h6"
                      data-testid="past-events-title"
                      sx={{ textAlign: "center", padding: "18px 0 18px 0" }}
                    >
                      Past Events
                    </Typography>
                  )}
                  {pastEvents.slice(0, 15).map((pastEvent, index) => (
                    <div
                      style={{ display: "contents" }}
                      key={selectedCalendar?._id + "_" + direction}
                    >
                      <NavigateOnClick
                        key={index}
                        id="event-navigation-container"
                      >
                        <Event
                          calendar={selectedCalendar}
                          event={pastEvent}
                          calendarId={selectedCalendar?._id}
                          showUpload={creatorView}
                          position={index}
                          isPastEvent={true}
                          isEmbedded={true}
                        />
                      </NavigateOnClick>
                      {loadAdsProp && index < limitRenderedAds && (
                        <Ad placementName="mpu" style={{ margin: "auto " }} />
                      )}
                    </div>
                  ))}
                </>
              ) : (
                <></>
              )}
            </>
          )}
        </>
      )}
    </div>
  );
}

const TimelineEmbeddedWC = ({ container }) => {
  const props = {
    handle: container.getAttribute("handle"),
    calendarId: container.getAttribute("calendarId"),
    direction: container.getAttribute("direction"),
    source: container.getAttribute("source"),
    loadAds: true,
    isWebComponent: true,
  };

  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: Infinity,
        cacheTime: Infinity,
        keepPreviousData: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
        onError: (error) => {
          console.error("Query error:", error);
        },
      },
    },
  });

  return (
    <React.StrictMode>
      <ThemeProvider theme={Theme}>
        <ErrorBoundary fallback={<ErrorComponent />}>
          <AuthProvider isEmbedded={true}>
            <SnackbarProvider>
              <Snackbar />
              <QueryClientProvider client={queryClient}>
                <Router>
                  <TimelineEmbedded {...props} />
                </Router>
              </QueryClientProvider>
            </SnackbarProvider>
          </AuthProvider>
        </ErrorBoundary>
      </ThemeProvider>
    </React.StrictMode>
  );
};

customElements.define(
  "stanza-timeline",
  reactToWebComponent(TimelineEmbeddedWC, React, ReactDOM)
);

export default TimelineEmbeddedWC;
