import { Component } from "react";
import Rollbar from "rollbar";
import { auth } from "src/firebase/firebase.js";
export * from "./ErrorComponent.js";

const isStaging = window.location.hostname.includes("stanza-testing.com");

function checkWebComponentScript() {
  var scripts = document.getElementsByTagName("script");
  var scriptFound = false;
  for (var i = 0; i < scripts.length; i++) {
    var src = scripts[i].getAttribute("src");
    if (src && src.includes("timelineembedded.js")) {
      scriptFound = true;
      break;
    }
  }
  return scriptFound;
}

const isWebComponent = checkWebComponentScript();

export const rollbar = new Rollbar({
  accessToken: process.env.REACT_APP_ROLLBAR_ACCESS_TOKEN,
  captureUncaught: true,
  captureUnhandledRejections: true,
  environment: isStaging ? "staging" : "production",
  // Rollbar is disabbled in web component because it's capturing errors from the parent app
  enabled: process.env.NODE_ENV === "production" && !isWebComponent,
});

export class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false,
      error: null,
      errorInfo: null,
    };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error, errorInfo });
    console.error(error);
    // Send error to parent window if app is embedded in iframe
    if (window.self !== window.top) {
      window.parent.postMessage(
        {
          type: "error",
          content: error.message,
          metadata: {
            source: "embedded-timeline-iframe",
            componentStack: errorInfo.componentStack,
          },
        },
        "*"
      );
    }
    const userSignedIn = Boolean(auth.currentUser);
    rollbar.error(error, errorInfo, { userSignedIn });
  }

  render() {
    if (this.state.hasError) {
      return this.props.fallback || null;
    }

    return this.props.children;
  }
}
