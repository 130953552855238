import { Box, Typography, Icon, Button } from "@mui/material";
import React, { memo, useMemo, useState, useEffect } from "react";
import InfiniteScrollList from "./InfiniteScrollList.js";
import { useTheme } from "@emotion/react";
import { useDimensions } from "src/utils/useDimensions.js";
import RecommendedCalendar from "./event/RecommendedCalendar.js";

const RenderCalendars = memo(
  ({
    events,
    onSubscribeAction,
    setShowRecommendedCalendars,
    setAddedCalendars,
    isMediumScreen,
    theme,
    id,
  }) =>
    events.map((event, index) => (
      <React.Fragment key={`${event.calendarId}_${id}`}>
        <RecommendedCalendar
          calendar={event.calendar}
          event={event.event}
          calendarId={event.calendarId}
          showUpload={false}
          position={index}
          onSubscribeAction={onSubscribeAction}
          setShowRecommendedCalendars={setShowRecommendedCalendars}
          setAddedCalendars={setAddedCalendars}
          showRanking={id === "Leaderboard" && [1, 2, 3, 4, 5, 6, 7, 8].includes(index + 1)}
          index={index}
        />
        {isMediumScreen && index !== events.length - 1 && (
          <hr
            style={{
              width: "100%",
              border: `0.5px solid ${theme.palette.text.tertiary}`,
            }}
          />
        )}
      </React.Fragment>
    ))
);

export const CalendarSection = ({
  calendarSection,
  onSubscribeAction,
  setShowRecommendedCalendars,
  setAddedCalendars,
  sx,
  infiniteScrollList = false,
  id,
  fetchSearchByTagsNextPage,
  hasMoreSearchByTagsPages,
}) => {
  const theme = useTheme();
  const { isMediumScreen } = useDimensions();
  const { title, calendars = [] } = calendarSection;
  const itemsPerPage = 10;
  const calendarsPages = useMemo(() => {
    const pages = [];
    for (let i = 0; i < calendars.length; i += itemsPerPage) {
      pages.push(calendars.slice(i, i + itemsPerPage));
    }
    return pages;
  }, [calendars]);
  const [calendarView, setCalendarView] = useState(
    calendarsPages.length ? calendarsPages[0] : []
  );

  useEffect(() => {
    setCalendarView(calendarsPages.length ? calendarsPages[0] : []);
  }, [calendarsPages]);

  if (!calendarSection) return null;

  return (
    <>
      <style>
        {`
    #calendar-section + div > #event-card {
      border-top: none;
    }
    #calendar-section + div {
      margin-top: 0px;
    }
    #calendar-section:first-of-type .MuiBox-root {
      border-top: none;
    }
  `}
      </style>
      <Box
        id="calendar-section"
        sx={{
          display: "flex",
          flexDirection: "column",
          borderTop: `1px solid ${theme.palette.text.tertiary}`,
          ...sx,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            py: 4.5,
            borderBottom:
              id !== "AZCalendars"
                ? `1px solid ${theme.palette.text.tertiary}`
                : "",
          }}
        >
          {title && (
            <Box
              sx={{ display: "flex", alignItems: "center", gap: 0.5, mb: 1 }}
            >
              <Icon
                baseClassName="material-symbols-outlined"
                sx={{
                  fontWeight: "300",
                  fontSize: "1.8rem",
                  color: "success.main",
                }}
              >
                check
              </Icon>
              <Typography variant="h2" data-testid="calendar-section-title">
                {title}
              </Typography>
            </Box>
          )}
          {infiniteScrollList ? (
            <>
              <InfiniteScrollList
                threshold={700}
                id={id}
                loadMore={fetchSearchByTagsNextPage}
                hasMore={hasMoreSearchByTagsPages}
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginTop: theme.spacing(1),
                  gap: theme.spacing(2),
                  flexDirection: "column",
                  ...(!isMediumScreen
                    ? {
                        display: "grid",
                        gridTemplateColumns: "1fr 1fr",
                        gap: theme.spacing(7, 4),
                      }
                    : {}),
                }}
                grid={!isMediumScreen}
              >
                <RenderCalendars
                  events={calendars}
                  onSubscribeAction={onSubscribeAction}
                  setShowRecommendedCalendars={setShowRecommendedCalendars}
                  setAddedCalendars={setAddedCalendars}
                  isMediumScreen={isMediumScreen}
                  theme={theme}
                  id={id}
                />
              </InfiniteScrollList>
              {!hasMoreSearchByTagsPages && (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    width: "100%",
                    padding: "40px 0 0 0",
                  }}
                >
                  <Typography variant={"subtitle1"} color="text.secondary">
                    ... and that's it 🎉
                  </Typography>
                </Box>
              )}
            </>
          ) : (
            <Box
              sx={{
                display: { xs: "flex", md: "grid" },
                alginItems: "center",
                mt: 1,
                gap: { xs: 2, md: theme.spacing(7, 4) },
                flexDirection: { xs: "column" },
                gridTemplateColumns: { md: "1fr 1fr" },
              }}
            >
              <RenderCalendars
                events={calendarView}
                onSubscribeAction={onSubscribeAction}
                setShowRecommendedCalendars={setShowRecommendedCalendars}
                setAddedCalendars={setAddedCalendars}
                isMediumScreen={isMediumScreen}
                theme={theme}
                id={id}
              />
            </Box>
          )}
          {!infiniteScrollList && calendarView.length < calendars.length && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mt: { xs: 3, md: 5.5 },
              }}
            >
              <Button
                variant="text"
                sx={{
                  textTransform: "none",
                  padding: 1,
                }}
                onClick={() =>
                  setCalendarView([
                    ...calendarView,
                    ...calendarsPages[
                      Math.floor(calendarView.length / itemsPerPage)
                    ],
                  ])
                }
              >
                <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                  <Icon
                    fontSize="medium"
                    sx={{ fontWeight: "300" }}
                    baseClassName="material-symbols-outlined"
                  >
                    visibility
                  </Icon>
                  <Typography variant="subtitle2">See More</Typography>
                </Box>
              </Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};
